import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@app/core/services/authentication.service';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    var firstAccess = JSON.parse(localStorage.getItem('firstAccess'));

    if (firstAccess == null) {
      localStorage.setItem('firstAccess', 'false');
      this.authService.isLoginSms().then(res => {
        if (res.body && route.url[0].path == 'login') {
          this.router.navigate(['/login-sms']);
          return false;
        }
        if (!res.body && route.url[0].path == 'login-sms') {
          this.router.navigate(['/login']);
          return false;
        }
      });
    }
    return true;
  }
}
