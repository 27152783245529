<div class="app-header" [ngClass]="{ 'system-message': systemMessage }">
  <nav class="navbar navbar-expand-lg landing-header">
    <a class="navbar-brand" [href]="homeUrl" [ngClass]="addMobileCss ? 'navbar-brand-mobile' : ''">
      <!--<img [src]="plexeLogoUrl" alt="plexe logo" />-->
      <!-- <img src="assets/logo-line.png" class="logo-line" /> -->
    </a>
    <a
      *ngIf="whitelable"
      class="navbar-brand navbar-brand-poweredby"
      [href]="homeUrl"
      [ngClass]="addMobileCss ? 'navbar-brand-mobile' : ''"
    >
    </a>
    <button
      class="navbar-toggler"
      type="button"
      aria-controls="navbar-menu"
      aria-label="Toggle navigation"
      (click)="toggleMenu()"
      [attr.aria-expanded]="!menuHidden"
    >
      <i class="fas fa-bars navbar-toggler-icon"></i>
    </button>
    <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
      <div class="navbar-nav ml-auto">
        <a *ngIf="!showCancelButton" class="nav-item nav-link" routerLink="/public" routerLinkActive="active">
          <span translate>Create Application</span>
        </a>
        <a class="nav-item nav-link" [href]="contactUsUrl" routerLinkActive="active">
          <span>Contact Us</span>
        </a>
        <ng-container *ngIf="duringCancelableApplication && showCancelButton">
          <!-- <a
            class="nav-item nav-link plexe-orange curs-pointer"
            (click)="cancelApplication()"
            routerLinkActive="active"
          >
            <span>Cancel Application</span>
          </a>-->

          <div class="nav-item">
            <button class="plexe-btn-primary" (click)="logout()" translate>Logout</button>
          </div>
        </ng-container>
        <div class="nav-item mr-30 customer-login" *ngIf="showLoginButton && !duringCancelableApplication">
          <button class="plexe-btn-primary" routerLink="/login">Login</button>
        </div>
      </div>
    </div>
  </nav>
</div>
