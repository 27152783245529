import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/internal/operators';
import { SignalREvents } from 'app/Constants';
import { HubConnection } from '@microsoft/signalr';
import { OriginationWriteApi } from '../proxy/Write/originationWriteApi';

export interface SignalREvent {
  channel: string;
  data: any;
}

const credentialsKey = 'credentials';

@Injectable()
export class SignalrService {
  private hubConnection: signalR.HubConnection;
  private hubUrl: string;
  private signalREvents: Subject<SignalREvent> = new Subject<SignalREvent>();

  private hubConnected = false;

  private reconnectionInterval = 5 * 1000;

  constructor(private baseUri: string, private writeApi: OriginationWriteApi) {
    this.hubUrl = baseUri + '/hubs/Origination';
  }

  sendMsg(msg: SignalREvent) {
    console.log(msg);
    this.signalREvents.next(msg);
  }

  getHubConnectionId(): string {
    if (this.hubConnected && this.hubConnection && this.hubConnection.connectionId) {
      return this.hubConnection.connectionId;
    } else {
      return '';
    }
  }

  getChannel(_channel: string): Observable<SignalREvent> {
    return this.signalREvents.asObservable().pipe(filter((msg: SignalREvent) => msg.channel === _channel));
  }

  initConnection(): Promise<any> {
    if ((!this.hubUrl || this.hubConnected) && this.hubConnection.connectionId) {
      this.saveCustomerConnection(this.hubConnection.connectionId);
      return;
    }

    this.hubConnected = true;

    this.hubConnection = this.createHubConnection();

    this.initReconnection(this.hubConnection);

    return this.hubConnection.start().catch(err => {
      this.closeConnection();
      setTimeout(() => this.initReconnection(this.hubConnection), 3000);
    });
  }

  closeConnection() {
    if (!this.hubConnection) {
      return;
    }
    this.resetCustomerConnection();
    this.hubConnection.stop().then(() => (this.hubConnected = false));
  }

  private createHubConnection(): HubConnection {
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .withStatefulReconnect()
      .withUrl(this.hubUrl)
      .build();

    // Set server timeout and keep-alive options
    connection.serverTimeoutInMilliseconds = 300000; // 5 mins
    connection.keepAliveIntervalInMilliseconds = 120000; // 2 mins

    Object.keys(SignalREvents).forEach(key => {
      const eventName = SignalREvents[key];
      connection.on(eventName, (p1, p2, p3, p4) => {
        this.sendMsg({ channel: eventName, data: { p1, p2, p3, p4 } });
      });
    });

    return connection;
  }

  private initReconnection(hubConnection: HubConnection) {
    if (hubConnection && hubConnection.connectionId) {
      this.saveCustomerConnection(hubConnection.connectionId);
    }
    hubConnection.onclose(() => {
      const intervalId = setInterval(() => {
        this.hubConnected = true;
        this.hubConnection = this.createHubConnection();
        this.hubConnection
          .start()
          .then(() => clearInterval(intervalId))
          .catch(err => {
            this.closeConnection();
            setTimeout(() => this.initReconnection(this.hubConnection), 3000);
          });
      }, this.reconnectionInterval);
    });
  }
  private saveCustomerConnection(connectionId: string) {
    const savedCredentials = JSON.parse(localStorage.getItem(credentialsKey));
    if (connectionId && savedCredentials) {
      this.writeApi.setCustomerConnection(savedCredentials.id, connectionId);
    }
  }
  private resetCustomerConnection() {
    const connectionId = this.getHubConnectionId();
    if (connectionId) {
      this.writeApi
        .resetCustomerConnection(connectionId)
        .then(() => {
          console.log('Customer connection reset successfully.');
        })
        .catch(error => {
          throw new Error(`Failed to send request: ${error}`);
        });
    }
  }
}
