// `.env.ts` is generated by the `npm run env` command
import { REGION } from '../app/Constants';
import env from './.env';

export const environment = {
  production: true,
  version: env.npm_package_version,
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US', 'fr-FR'],
  blinkIdLicenseKey:
    'sRwAAAYecGxleGUtcG9jLXFhLmF6dXJld2Vic2l0ZXMubmV0B/0ap+d2f6j1KMD0cR7/BpWhM6+QSZXtN5V70OD3//zKRCdtSYkgZWuCQZn8rT1ZNnn6bBgozh9gAxyaPPQ3arjFlXNkPSq1fKA/52iJDeJWd/Qx84iGPsjWIgl6BNGaoUVDiZAHnEHVsBHe/k2mLQC9FKcFsRommJGWuZbY2ec+qCMpl9D2aWHuN0vKD2tFE9f/nMXXDD2VjQrvh7VZi4vZTqzOtb1lec5CWcrBKZlCD9cng/1JD5y3o8qgbboWMSNvXb0F3g6a',
  defaultRegion: REGION.AU
};
