/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from '@azure/ms-rest-js';

export const baseUrl: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'baseUrl'],
  mapper: {
    serializedName: 'BaseUrl',
    type: {
      name: 'String'
    }
  }
};
export const code: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'code'],
  mapper: {
    serializedName: 'code',
    type: {
      name: 'String'
    }
  }
};
export const column: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'column'],
  mapper: {
    serializedName: 'column',
    defaultValue: '',
    type: {
      name: 'String'
    }
  }
};
export const currentPassword: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'currentPassword'],
  mapper: {
    serializedName: 'CurrentPassword',
    type: {
      name: 'String'
    }
  }
};
export const direction: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'direction'],
  mapper: {
    serializedName: 'direction',
    defaultValue: '',
    type: {
      name: 'String'
    }
  }
};
export const email0: msRest.OperationURLParameter = {
  parameterPath: 'email',
  mapper: {
    required: true,
    serializedName: 'email',
    type: {
      name: 'String'
    }
  }
};
export const email1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'email'],
  mapper: {
    serializedName: 'email',
    type: {
      name: 'String'
    }
  }
};
export const force: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'force'],
  mapper: {
    serializedName: 'force',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const fromDateTimeUtc: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'fromDateTimeUtc'],
  mapper: {
    serializedName: 'fromDateTimeUtc',
    type: {
      name: 'DateTime'
    }
  }
};
export const id: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'id'],
  mapper: {
    serializedName: 'id',
    type: {
      name: 'String'
    }
  }
};
export const length: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'length'],
  mapper: {
    serializedName: 'length',
    defaultValue: 4,
    type: {
      name: 'Number'
    }
  }
};
export const orderBy: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'orderBy'],
  mapper: {
    serializedName: 'orderBy',
    type: {
      name: 'String'
    }
  }
};
export const otp: msRest.OperationURLParameter = {
  parameterPath: 'otp',
  mapper: {
    required: true,
    serializedName: 'otp',
    type: {
      name: 'String'
    }
  }
};
export const password: msRest.OperationQueryParameter = {
  parameterPath: 'password',
  mapper: {
    required: true,
    serializedName: 'Password',
    type: {
      name: 'String'
    }
  }
};
export const passwordConfirmation: msRest.OperationQueryParameter = {
  parameterPath: 'passwordConfirmation',
  mapper: {
    required: true,
    serializedName: 'PasswordConfirmation',
    type: {
      name: 'String'
    }
  }
};
export const passwordResetToken0: msRest.OperationURLParameter = {
  parameterPath: 'passwordResetToken',
  mapper: {
    required: true,
    serializedName: 'passwordResetToken',
    type: {
      name: 'String'
    }
  }
};
export const passwordResetToken1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'passwordResetToken'],
  mapper: {
    serializedName: 'passwordResetToken',
    type: {
      name: 'String'
    }
  }
};
export const phone: msRest.OperationURLParameter = {
  parameterPath: 'phone',
  mapper: {
    required: true,
    serializedName: 'phone',
    type: {
      name: 'String'
    }
  }
};
export const searchText: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'searchText'],
  mapper: {
    serializedName: 'searchText',
    type: {
      name: 'String'
    }
  }
};
export const skip: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'skip'],
  mapper: {
    serializedName: 'skip',
    type: {
      name: 'Number'
    }
  }
};
export const take0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'take'],
  mapper: {
    serializedName: 'take',
    type: {
      name: 'Number'
    }
  }
};
export const take1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'take'],
  mapper: {
    serializedName: 'take',
    defaultValue: 30,
    type: {
      name: 'Number'
    }
  }
};
export const toDateTimeUtc: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'toDateTimeUtc'],
  mapper: {
    serializedName: 'toDateTimeUtc',
    type: {
      name: 'DateTime'
    }
  }
};
export const userEmail0: msRest.OperationURLParameter = {
  parameterPath: 'userEmail',
  mapper: {
    required: true,
    serializedName: 'userEmail',
    type: {
      name: 'String'
    }
  }
};
export const userEmail1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'userEmail'],
  mapper: {
    serializedName: 'userEmail',
    type: {
      name: 'String'
    }
  }
};
export const userId: msRest.OperationURLParameter = {
  parameterPath: 'userId',
  mapper: {
    required: true,
    serializedName: 'userId',
    type: {
      name: 'Uuid'
    }
  }
};
export const username: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'username'],
  mapper: {
    serializedName: 'username',
    type: {
      name: 'String'
    }
  }
};
export const userName0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'userName'],
  mapper: {
    serializedName: 'userName',
    type: {
      name: 'String'
    }
  }
};
export const userName1: msRest.OperationURLParameter = {
  parameterPath: 'userName',
  mapper: {
    required: true,
    serializedName: 'userName',
    type: {
      name: 'String'
    }
  }
};
