import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SecurityContext } from '@app/core/interfaces/i-security-context';
import { AppNavigatorService } from '@app/core/services/app-navigator.service';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { UserService } from '@app/core/services/user.service';
import { markFormGroupTouched } from '@app/PlexeUtils';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IpService } from '@app/core/services/ip.service';

const CORPRATE_CARDHOLDER = 'corpratecardholder';
const EMPLOYEE = 'employee';

@Component({
  selector: 'app-password-expire',
  templateUrl: './password-expire.component.html',
  styleUrls: ['./password-expire.component.scss']
})
export class PasswordExpireComponent implements OnInit {
  version: string = environment.version;
  error: string;
  passwordExpiresForm: FormGroup;
  isLoading = false;
  menuHidden = true;
  token: string;
  email: string;
  passwordResetComplete = true;
  isLoggingIn = false;
  ipAddress: string;
  constructor(
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private http: HttpClient,
    private ipservice: IpService,
    private authenticationService: AuthenticationService,
    private navService: AppNavigatorService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.email = localStorage.getItem('e');

    this.ipservice.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  resetPassword() {
    if (this.passwordExpiresForm.invalid) {
      markFormGroupTouched(this.passwordExpiresForm);
      return;
    }

    if (!this.email) {
      this.toastrService.error('No Reset Password email found');
      return;
    }

    this.isLoading = true;

    this.authenticationService
      .passwordExpiresReset(this.email, this.passwordExpiresForm.value)
      .then(() => {
        this.toastrService.success('Password reset successfully.');
        this.passwordResetComplete = true;
      })
      .catch(err => {
        this.toastrService.error('Failed to reset password');
      })
      .finally(() => (this.isLoading = false));
  }

  checkPasswords(group: FormGroup) {
    if (!group) {
      return;
    }
    const pass = group.controls.password.value;
    const confirmPass = group.controls.passwordConfirmation.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  okButton() {
    this.isLoggingIn = true;
    const loginModel = {
      username: this.email,
      password: this.passwordExpiresForm.value.password,
      ipAddress: this.ipAddress
    };
    this.authenticationService
      .login(loginModel, true)
      .pipe(
        switchMap(credentials =>
          this.authenticationService.requiresTwoWayAuth(credentials)
            ? this.router.navigate(['/two-factor'])
            : this.postLoginActions(credentials)
        )
      )
      .subscribe(_ => {}, err => {});
  }

  private createForm() {
    this.passwordExpiresForm = this.formBuilder.group(
      {
        currentPassword: ['', Validators.required],
        password: ['', Validators.required],
        passwordConfirmation: ['', Validators.required]
      },
      { validators: [this.checkPasswords] }
    );
  }

  private postLoginActions(credentials: SecurityContext): Observable<any> {
    if (credentials.userType.toLowerCase() === 'partner') {
      localStorage.setItem('partnerAccountConnected', 'false');
      return from(
        this.userService
          .loadPartnerHomepageData(credentials.id)
          .then(_ => {
            return this.router.navigate(['/partner/home'], { replaceUrl: true });
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else if (credentials.userType.toLowerCase() === 'customer' || credentials.userType.toLowerCase() === 'admin') {
      return from(
        this.userService
          .loadCustomerForHomePage(credentials.id)
          .then(_ => {
            return this.navService.navigateToCustomerHome();
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else {
      throw new Error('User not allowed');
    }
  }
}
