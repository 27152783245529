import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { SecurityContext } from '@app/core/interfaces/i-security-context';
import { AppNavigatorService } from '@app/core/services/app-navigator.service';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { UserService } from '@app/core/services/user.service';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SignalrService } from '../../core/services/signalr.service';
import { IpService } from '../../core/services/ip.service';

@Component({
  selector: 'app-login-sms',
  templateUrl: './login-sms.component.html',
  styleUrls: ['./login-sms.component.scss']
})
export class LoginSmsComponent implements OnInit {
  version: string = environment.version;
  loginForm: FormGroup;
  menuHidden = true;
  isLoading = false;
  isSmsLogin = false;
  failed = false;
  digit1;
  digit2;
  digit3;
  digit4;
  ipAddress: string;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private router: Router,
    private ipservice: IpService,
    private navService: AppNavigatorService,
    private signalrService: SignalrService
  ) {}

  get digitsFilled() {
    return this.digit1 && this.digit2 && this.digit3 && this.digit4;
  }

  ngOnInit() {
    sessionStorage.setItem('isSms', 'true');
    localStorage.removeItem('announcement');
    this.ipservice.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });

    let params: any = {};
    new URL(window.location.href).searchParams.forEach(function(val, key) {
      params[key.toLowerCase()] = val;
    });

    this.createForm(params);
    this.login();
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  login() {
    if (this.loginForm.invalid) {
      return;
    }
    this.isLoading = true;

    this.userService
      .sendLoginOtp({ email: this.loginForm.value.username })
      .then(res => {
        this.isSmsLogin = true;
        setTimeout(() => {
          const e = document.getElementById('digit-1');
          if (e) {
            e.focus();
          }
        }, 500);
      })
      .catch(_ => this.toastrService.error('Failed to send Code.'))
      .finally(() => (this.isLoading = false));
  }

  resend() {
    this.login();
    this.digit1 = this.digit2 = this.digit3 = this.digit4 = '';
    this.failed = false;
  }

  goToComplete(event?: any) {
    if (event && event.which === 8) {
      return;
    }

    if (!this.digitsFilled) {
      this.toastrService.error('Please fill code first.');
      return;
    }

    this.failed = false;
    this.isLoading = true;
    const code = '' + this.digit1 + this.digit2 + this.digit3 + this.digit4;

    this.authenticationService
      .validateTwoWayAuth({ username: this.loginForm.value.username, code, ipAddress: this.ipAddress })
      .pipe(switchMap(credentials => this.postLoginActions(credentials)))
      .subscribe(
        _ => {},
        _ => {
          this.failed = true;
          this.isLoading = false;
        }
      );
  }

  onKeyDown(event: any) {
    if ((event.which > 47 && event.which < 58) || (event.which >= 96 && event.which <= 105)) {
      event.currentTarget.value = '';
    }
  }

  onKeyUp(event: any, nextElemId?: string, prevElemId?: string) {
    if (event.which === 8 && prevElemId) {
      const e = document.getElementById(prevElemId);
      e.focus();
    } else if (((event.which > 47 && event.which < 58) || (event.which >= 96 && event.which <= 105)) && nextElemId) {
      const e = document.getElementById(nextElemId);
      e.focus();
    }
  }

  private createForm(params) {
    this.loginForm = this.formBuilder.group({
      username: new FormControl(params.u ? params.u : '', [Validators.required, Validators.email])
    });
  }

  private postLoginActions(credentials: SecurityContext): Observable<any> {
    const connectionId = this.signalrService.getHubConnectionId();
    if (connectionId != '') {
      this.userService.setCustomerConnection(credentials.id, connectionId);
    }
    if (credentials.userType.toLowerCase() === 'agent') {
      return from(
        this.userService
          .loadAgentHomepageData(credentials.id)
          .then(_ => {
            return this.router.navigate(['/agent/home'], { replaceUrl: true });
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else if (credentials.userType.toLowerCase() === 'partner') {
      return from(
        this.userService
          .loadPartnerHomepageData(credentials.id)
          .then(_ => {
            return this.router.navigate(['/partner/home'], { replaceUrl: true });
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else if (credentials.userType.toLowerCase() === 'customer' || credentials.userType.toLowerCase() === 'admin') {
      return from(
        this.userService
          .loadCustomerForHomePage(credentials.id)
          .then(_ => {
            return this.navService.navigateToCustomerHome();
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else {
      throw new Error('User not allowed');
    }
  }
}
