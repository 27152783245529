import { Component } from '@angular/core';

import { environment } from 'environments/environment';
import { Logger } from 'app/core/services/logger.service';

const log = new Logger('Login');

@Component({
  selector: 'app-general-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class GeneralErrorComponent {
  version: string = environment.version;
  error: string;
  constructor() {
    // this.loaderService.setStatus(true);
  }
}
