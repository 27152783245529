import { Injectable } from '@angular/core';
import { TokenCredentials } from '@azure/ms-rest-js';

export class MyToken extends TokenCredentials {
  constructor(token: string) {
    super(token);
  }

  setToken(t: string) {
    this.token = t;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TokenHolderService {
  private token: MyToken = new MyToken('default');

  constructor() {}

  //  ____   ___  ____ _____
  // |  _ \ / _ \/ ___|_   _|
  // | |_) | | | \___ \ | |
  // |  __/| |_| |___) || |
  // |_|    \___/|____/ |_|

  //   ____ _____ _____
  //  / ___| ____|_   _|
  // | |  _|  _|   | |
  // | |_| | |___  | |
  //  \____|_____| |_|

  getToken() {
    return this.token;
  }

  //  ____  _____ _____
  // / ___|| ____|_   _|
  // \___ \|  _|   | |
  //  ___) | |___  | |
  // |____/|_____| |_|

  setToken(newToken: string) {
    this.token.setToken(newToken);
  }
}
