<nav class="side-nav bg-gradient rounded-xl d-flex flex-column px-3 py-5 h-100">
  <h2 class="font-weight-bold mb-3 d-none d-sm-block text s-x5 px-4 text-left plexe-menu-header-1" *ngIf="!noMenuItem">
    Home
  </h2>
  <div class="flex-spacer d-flex flex-column" style="margin-top: 100px;">
    <div class="px-4 pb-5 w-100 flex-spacer overflow-auto" *ngIf="!noMenuItem">
      <div
        *ngIf="isPartnerMode"
        class="nav-item plexe-light-blue-40-solid text-left py-2 text s-x4 border-bottom border-sidemenu-light-blue curs-pointer outline-none d-flex align-items-center"
        routerLink="/partner/home"
        routerLinkActive="active"
      >
        <span class="flex-spacer py-1 plexe-menu-header-1">Partner Dashboard</span>
        <i class="fas fa-angle-right nav-right-arrow"></i>
      </div>
      <div
        class="nav-item plexe-light-blue-40-solid text-left py-2 text s-x4 border-bottom border-sidemenu-light-blue curs-not-allowed outline-none"
        [ngClass]="{
          'active font-weight-bold':
            registerActive.isActive ||
            register2Active.isActive ||
            codeVerifyActive.isActive ||
            extraInformationActive.isActive
        }"
      >
        <div class="py-1 d-flex align-items-center">
          <span class="flex-spacer text s-x02">Register</span>
          <i class="fas fa-angle-right nav-right-arrow"></i>
        </div>
      </div>
      <a
        routerLink="/public/new-application/register"
        routerLinkActive
        #registerActive="routerLinkActive"
        style="display: none"
      ></a>
      <a
        routerLink="/public/new-application/register2"
        routerLinkActive
        #register2Active="routerLinkActive"
        style="display: none"
      ></a>
      <a
        routerLink="/public/new-application/code-verify"
        routerLinkActive
        #codeVerifyActive="routerLinkActive"
        style="display: none"
      ></a>
      <a
        routerLink="/public/new-application/extra-information"
        routerLinkActive
        #extraInformationActive="routerLinkActive"
        style="display: none"
      ></a>
      <a routerLink="/plaid" routerLinkActive #plaidRedirectActive="routerLinkActive" style="display: none"></a>
      <div
        class="nav-item plexe-light-blue-40-solid text-left py-2 text s-x4 border-bottom border-sidemenu-light-blue curs-not-allowed outline-none"
        [ngClass]="{
          'active font-weight-bold':
            bankFeedSelectActive.isActive ||
            bankFeedFindActive.isActive ||
            bankFeedDetailsActive.isActive ||
            bankFeedAccountActive.isActive ||
            accountingSoftwareActive.isActive ||
            accountingActive.isActive ||
            plaidRedirectActive.isActive
        }"
      >
        <div class="py-1 d-flex align-items-center">
          <span class="flex-spacer text s-x02">Connect</span>
          <i class="fas fa-angle-right nav-right-arrow"></i>
        </div>
      </div>
      <a
        [routerLink]="'/public/new-application/' + shortCode + '/bank-feed-select'"
        routerLinkActive
        #accountingSoftwareActive="routerLinkActive"
        style="display: none"
      ></a>
      <a
        [routerLink]="'/public/accounting'"
        routerLinkActive
        #accountingActive="routerLinkActive"
        style="display: none"
      ></a>
      <a
        [routerLink]="'/public/new-application/' + shortCode + '/bank-feed-select'"
        routerLinkActive
        #bankFeedSelectActive="routerLinkActive"
        style="display: none"
      ></a>
      <a
        [routerLink]="'/public/new-application/' + shortCode + '/bank-feed-find'"
        routerLinkActive
        #bankFeedFindActive="routerLinkActive"
        style="display: none"
      ></a>
      <a
        [routerLink]="'/public/new-application/' + shortCode + '/bank-feed-details'"
        routerLinkActive
        #bankFeedDetailsActive="routerLinkActive"
        style="display: none"
      ></a>
      <a
        [routerLink]="'/public/new-application/' + shortCode + '/bank-feed-account'"
        routerLinkActive
        #bankFeedAccountActive="routerLinkActive"
        style="display: none"
      ></a>
      <div
        class="nav-item plexe-light-blue-40-solid text-left py-2 text s-x4 border-bottom border-sidemenu-light-blue curs-not-allowed outline-none"
        [ngClass]="{
          'active font-weight-bold':
            referActive.isActive || thankyouActive.isActive || indicativeLineOfCreditActive.isActive
        }"
      >
        <div class="py-1 d-flex align-items-center">
          <span class="flex-spacer text s-x02">Complete</span>
          <i class="fas fa-angle-right nav-right-arrow"></i>
        </div>
      </div>
      <a
        [routerLink]="'/public/new-application/' + shortCode + '/refer'"
        routerLinkActive
        #referActive="routerLinkActive"
        style="display: none"
      ></a>
      <a
        [routerLink]="'/public/new-application/' + shortCode + '/thank-you'"
        routerLinkActive
        #thankyouActive="routerLinkActive"
        style="display: none"
      ></a>
      <a
        [routerLink]="'/public/new-application/' + shortCode + '/indicative-line-of-credit'"
        routerLinkActive
        #indicativeLineOfCreditActive="routerLinkActive"
        style="display: none"
      ></a>
    </div>

    <p class="mb-0 mt-3 px-4 text s-x02 text-left bottom-links plexe-text-t4" *ngIf="isBottomLink">
      By clicking continuing, you are agreeing to our
      <a target="_blank" class="text-decoration-underline plexe-light-blue-40-solid" [href]="termsAndConditionsUrl"
        >Terms & Conditions</a
      >
      and
      <a target="_blank" class="text-decoration-underline plexe-light-blue-40-solid" [href]="privacyUrl"
        >Privacy Policy</a
      >
    </p>
    <p class="mb-0 mt-3 px-4 text s-x02 text-left bottom-links" *ngIf="isFaq">
      <a
        class="plexe-light-blue-40-solid text-decoration-underline text-uppercase font-weight-bold"
        target="_blank"
        [href]="homeUrl + '/faq'"
        >Faq</a
      >
    </p>
  </div>
</nav>
