import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class HomePageRedirectGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    var whitelabel_enabled = JSON.parse(localStorage.getItem('whitelabel_enabled'));
    var whitelabel_ReturnUrl = localStorage.getItem('Whitelabel_ReturnUrl');
    if (whitelabel_enabled && whitelabel_ReturnUrl != '') {
      window.location.href = whitelabel_ReturnUrl;
    } else {
      //  this.router.navigate(['/']);
      // window.location.href = window['appConfig']['homeUrl'];
    }
    return false;
  }
}
