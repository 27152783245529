<!-- <app-system-notification></app-system-notification> -->
<app-public-header [showLoginButton]="true"></app-public-header>
<div
  class="bg-image-gradient height-100vh with-header overflow-auto p-3 p-sm-5 d-flex flex-column align-items-center justify-content-center"
>
  <!-- CONTENT -->
  <div class="w-100 widescreen-scale">
    <h1 class="mb-1 text-center font-weight-bold">Change password</h1>
    <p class="text-center text-white font-weight-bold">Insert your new password</p>
    <form (ngSubmit)="resetPassword()" [formGroup]="forgotPasswordForm" novalidate>
      <div
        class="d-flex flex-column py-0 flex-spacer mt-5 rounded-xl bg-white p-3 p-sm-5 mx-auto box-shadow"
        style="max-width: 500px;"
      >
        <div class="form-group flex-spacer">
          <div class="mb-4 position-relative">
            <label class="d-block text-uppercase font-weight-bold mb-1 text s-x04 plexe-label-text">New Password</label>
            <input
              type="password"
              class="plexe-form-field bg-white pr-4"
              formControlName="password"
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$"
            />
            <img
              class="position-absolute"
              style="top: 28px; right: 5px;"
              src="assets/svgs/broker/check_yellow.svg"
              *ngIf="forgotPasswordForm.controls.password.valid"
            />
            <span hidden translate>Password</span>
            <small
              [hidden]="forgotPasswordForm.controls.password.valid || forgotPasswordForm.controls.password.untouched"
              class="text-danger"
              translate
            >
              <div
                class="text-left"
                *ngIf="
                  forgotPasswordForm.controls.password.errors && forgotPasswordForm.controls.password.errors.required
                "
              >
                Password is required
              </div>
              <div
                class="text-left"
                *ngIf="
                  forgotPasswordForm.controls.password.errors && forgotPasswordForm.controls.password.errors.pattern
                "
              >
                Password is invalid. Password must contain minimum 8 characters, including 1 digit, 1 uppercase &
                lowercase letter.
              </div>
            </small>
          </div>
          <div class="position-relative">
            <label class="d-block text-uppercase font-weight-bold mb-1 text s-x04 plexe-label-text"
              >Confirm Password</label
            >
            <input type="password" class="plexe-form-field bg-white pr-4" formControlName="passwordConfirmation" />
            <img
              class="position-absolute"
              style="top: 28px; right: 5px;"
              src="assets/svgs/broker/check_yellow.svg"
              *ngIf="forgotPasswordForm.controls.passwordConfirmation.valid"
            />
            <span hidden translate>Confirm Password</span>
            <small [hidden]="forgotPasswordForm.controls.passwordConfirmation.untouched" class="text-danger" translate>
              <div
                class="text-left"
                *ngIf="
                  forgotPasswordForm.controls.passwordConfirmation.errors &&
                  forgotPasswordForm.controls.passwordConfirmation.errors.required
                "
              >
                Confirm Password is required
              </div>
              <div
                class="text-left"
                *ngIf="
                  forgotPasswordForm.controls.password.touched &&
                  forgotPasswordForm.invalid &&
                  forgotPasswordForm.errors &&
                  forgotPasswordForm.errors.notSame &&
                  !(
                    forgotPasswordForm.controls.passwordConfirmation.errors &&
                    forgotPasswordForm.controls.passwordConfirmation.errors.required
                  )
                "
              >
                Passwords do not match
              </div>
            </small>
          </div>
        </div>
      </div>
      <div class="mt-5 text-center text-sm-left">
        <button
          class="plexe-btn-primary d-block mx-auto width-220 h-auto px-3 py-2 mb-3"
          type="submit"
          [disabled]="forgotPasswordForm.invalid || isLoading"
        >
          <i class="fa fa-spinner fa-spin mr-1" aria-hidden="true" [hidden]="!isLoading"></i>
          <span translate>Set new password</span>
        </button>
        <p class="plexe-dark-blue text-center m-0">Remember your old password?</p>
        <a
          class="d-block forgot-password-link text-decoration-underline text-black text-center font-weight-bold"
          routerLink="/login"
          >Login here</a
        >
      </div>
    </form>
  </div>
</div>
