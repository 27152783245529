<div
  (mouseover)="setReadCommunications()"
  class="communication-panel ml-4 bg-white rounded-xl p-4 d-none d-sm-block d-sm-flex flex-column h-100 text-left box-shadow"
>
  <div class="d-flex ">
    <h3 class="d-flex text-uppercase text plexe-header4 font-weight-bold">
      Communication
    </h3>
    <i class="fa fa-bell mr-2 plexe-orange" [ngStyle]="{ display: hasNewCommunication ? 'block' : 'none' }"></i>
  </div>
  <div class="my-4 flex-spacer" style="overflow: hidden;" #messagesDiv>
    <p
      class="text s-x04 mb-2"
      *ngFor="let message of messages"
      #messageP
      [innerHTML]="getParsedMessageHtml(message)"
      [ngClass]="{
        'plexe-text-t2': message.user === 'Me' || message.user === 'You',
        'plexe-text-t3': message.user === 'Plexe' || message.user === 'Team',
        'plexe-text-green': message.user === 'Agent' || message.user === 'Partner'
      }"
    ></p>
  </div>
  <div>
    <input
      class="d-block plexe-bg-more-light-grey border-0 mb-3 w-100 p-2"
      [(ngModel)]="sMessage"
      placeholder="Type your message here"
    />
    <button class="plexe-btn-primary d-block ml-auto" (click)="sendMessage(sMessage)" [disabled]="isLoading">
      <i class="fa fa-spinner fa-spin mr-1" [hidden]="!isLoading"></i>
      Submit
    </button>
  </div>
</div>
<div
  id="mobile-view-communication"
  (mouseover)="setReadCommunications()"
  style="width: auto;"
  class="communication-panel bg-white rounded-xl pb-4 d-block mb-block d-sm-none d-sm-none flex-column h-100 text-left box-shadow"
>
  <div class="d-flex ">
    <h3 class="d-flex text-uppercase text plexe-header4 font-weight-bold">
      Communication
    </h3>
    <i class="fa fa-bell mr-2 plexe-orange" [ngStyle]="{ display: hasNewCommunication ? 'block' : 'none' }"></i>
  </div>
  <div class="my-4 flex-spacer" style="overflow: hidden;" #messagesDiv>
    <p
      class="text s-x04 mb-2"
      *ngFor="let message of messages"
      #messageP
      [innerHTML]="getParsedMessageHtml(message)"
      [ngClass]="{
        'plexe-text-t2': message.user === 'Me' || message.user === 'You',
        'plexe-text-t3': message.user === 'Plexe' || message.user === 'Team',
        'plexe-text-green': message.user === 'Agent' || message.user === 'Partner'
      }"
    ></p>
  </div>
  <div>
    <input
      class="d-block plexe-bg-more-light-grey border-0 mb-3 w-100 p-2"
      [(ngModel)]="sMessage"
      placeholder="Type your message here"
    />
    <button class="plexe-btn-primary d-block ml-auto" (click)="sendMessage(sMessage)" [disabled]="isLoading">
      <i class="fa fa-spinner fa-spin mr-1" [hidden]="!isLoading"></i>
      Submit
    </button>
  </div>
</div>
