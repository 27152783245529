import { Component } from '@angular/core';

import { environment } from 'environments/environment';

@Component({
  selector: 'app-error404',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class Error404Component {
  version: string = environment.version;
  error: string;
  constructor() {}
}
