<app-public-header [showLoginButton]="true"></app-public-header>

<div
  class="bg-image-gradient height-100vh with-header overflow-auto p-3 p-sm-5 d-flex flex-column align-items-center justify-content-center"
>
  <div class="d-flex flex-column mt-5 p-sm-5 mx-auto align-items-center bg-white rounded-2xl py-4 px-3 box-shadow">
    <div>
      <h1 class="mb-0">
        Thank you
      </h1>
    </div>
    <div class="mt-40 mb-60 text-center">
      <h3>An email has been sent to reset your credentials</h3>
    </div>
    <button class="plexe-btn-primary d-block mx-auto width-220 h-auto px-3 py-2 mb-3" routerLink="/">
      Go Home
    </button>
  </div>
</div>
