import { Component, HostBinding, OnDestroy, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from './loader.service';
import { AppNavigatorService } from '@app/core/services/app-navigator.service';

// export const LOADER_TYPE = {
//   NORMAL: 0,
//   WITH_PUBLIC_TEMPLATE: 1
// };

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {
  @Input() withPublicTemplate = false;
  @Input() noMenuItem = false;
  message = '';
  showButton = false;
  @HostBinding('class.show') isLoading = false;
  progressBarData: { value: number; max: number } = null;
  // isLoading: boolean = false;
  messageSubscription: Subscription;
  loadingSubscription: Subscription;
  showButtonSubscription: Subscription;
  progressBarSubscription: Subscription;
  constructor(private loaderService: LoaderService, private appNavigatorService: AppNavigatorService) {
    this.messageSubscription = this.loaderService.getMessage().subscribe(message => {
      this.message = message;
    });
    this.loadingSubscription = this.loaderService.getStatus().subscribe(loading => {
      this.isLoading = loading;
      if (!loading) {
        this.message = '';
        this.progressBarData = null;
      }
    });
    this.showButtonSubscription = this.loaderService.getShowButton().subscribe(showButton => {
      this.showButton = showButton;
    });
    this.progressBarSubscription = this.loaderService.getProgressBar().subscribe(data => {
      this.progressBarData = data;
    });
  }

  ngOnInit() {
    this.progressBarData = null;
  }
  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
    this.loadingSubscription.unsubscribe();
    this.showButtonSubscription.unsubscribe();
  }

  navHome() {
    this.appNavigatorService.navigateApplicationUrlByStatus();
  }
}
