<app-public-pages-template class="w-100" *ngIf="withPublicTemplate" [isBackdrop]="true">
  <ng-container public-pages-content>
    <!-- CONTENT -->
    <!--<div class="text-center">
      <a class="navbar-brand m_logo my-4 logo-in-loader" routerLink="/" translate>-->
    <!--<img class="m_logo my-4 logo-in-loader" [src]="plexeLogoUrl" />-->
    <!--</a>
    </div>-->
    <div class="d-flex flex-spacer align-items-center">
      <div class="mx-auto loading-container" style="margin-top: -100px;">
        <span class="text font-weight-bold flex-spacer text-black mb-0">Please wait</span>
        <p class="text-center plexe-para-text-1">{{ message }}</p>
        <img *ngIf="!progressBarData" src="assets/svgs/loader.svg" class="loader-spin" />
        <p *ngIf="progressBarData">
          <ngb-progressbar
            [showValue]="false"
            [animated]="true"
            [striped]="true"
            type="info"
            [max]="progressBarData.max"
            [value]="progressBarData.value"
          ></ngb-progressbar>
        </p>
        <div class="mt-40">
          <button class="plexe-btn-primary" *ngIf="showButton" (click)="navHome()">Home</button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container public-pages-mobile-content>
    <!-- MOBILE CONTENT -->
    <div class="d-flex flex-spacer align-items-center">
      <div class="mx-auto loading-container">
        <span class="text font-weight-bold flex-spacer text-black mb-0">Please wait</span>
        <p class="text-center plexe-para-text-1">{{ message }}</p>
        <img *ngIf="!progressBarData" src="assets/svgs/loader.svg" class="loader-spin" />
        <p *ngIf="progressBarData">
          <ngb-progressbar
            [showValue]="false"
            [animated]="true"
            [striped]="true"
            type="info"
            [max]="progressBarData.max"
            [value]="progressBarData.value"
          ></ngb-progressbar>
        </p>
        <div class="mt-40">
          <button class="plexe-btn-primary" *ngIf="showButton" (click)="navHome()">Home</button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container mobile-bottom-link>
    <!-- MOBILE BOTTOM -->
  </ng-container>
</app-public-pages-template>

<div class="text-xs-center" *ngIf="!withPublicTemplate">
  <div class="mx-auto loading-container">
    <h2 class="plexe-dark-blue more-bold mb-0">Please wait</h2>
    <h3 class="plexe-dark-blue">{{ message }}</h3>
    <img *ngIf="!progressBarData" src="assets/svgs/loader.svg" class="loader-spin" />
    <p *ngIf="progressBarData">
      <ngb-progressbar
        [showValue]="false"
        [animated]="true"
        [striped]="true"
        type="info"
        [max]="progressBarData.max"
        [value]="progressBarData.value"
      ></ngb-progressbar>
    </p>
    <div class="mt-40">
      <button class="plexe-btn-primary" *ngIf="showButton" (click)="navHome()">Home</button>
    </div>
  </div>
</div>
