import { Component, Input, OnInit } from '@angular/core';
import { DataStore } from '@app/DataStore';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { REGION } from '../../../Constants';

@Component({
  selector: 'app-public-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class PublicSidemenuComponent implements OnInit {
  defaultRegion = window['appConfig']['defaultRegion'];
  hideAbn = this.defaultRegion == REGION.US;
  termsAndConditionsUrl = window['appConfig']['termsAndConditionsUrl'];
  privacyUrl = window['appConfig']['privacyUrl'];
  @Input() noMenuItem = false;
  @Input() isBottomLink = false;
  @Input() isFaq = false;
  isPartnerMode = false;
  homeUrl = window['appConfig']['homeUrl'];
  constructor(private authenticationService: AuthenticationService) {}

  get shortCode() {
    if (DataStore.application) {
      return DataStore.application.shortCode;
    }

    return null;
  }

  ngOnInit() {
    this.isPartnerMode = this.authenticationService.isPartner();
  }
}
