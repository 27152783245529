import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-application-processing',
  templateUrl: './application-processing.component.html',
  styleUrls: ['./application-processing.component.scss']
})
export class ApplicationProcessingComponent implements OnInit {
  constructor() {}
  ngOnInit() {}
}
