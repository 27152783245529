import { Injectable } from '@angular/core';
import { ApplicationService } from '@app/core/services/application.service';
import { UserService } from '@app/core/services/user.service';
import { CreditService } from '@app/core/services/credit.service';
import { LoanService } from '@app/core/services/loan.service';

@Injectable()
export class DataService {
  constructor(
    private applicationService: ApplicationService,
    private userService: UserService,
    private creditService: CreditService,
    private loanService: LoanService
  ) {}

  resetApplicationContext() {
    this.applicationService.resetApplication();
    this.userService.resetCustomer();
    this.creditService.resetOffer();
  }

  resetLoanContext() {
    this.loanService.resetDisplayedLoan();
  }

  resetCustomerContext() {
    this.resetApplicationContext();
    this.loanService.resetDisplayedLoan();
    this.userService.resetCustomerData();
  }
}
