import { Injectable } from '@angular/core';
import { OriginationReadApi } from '@app/core/proxy/Read/originationReadApi';
import { DataStore } from '@app/DataStore';

@Injectable()
export class SystemService {
  constructor(private readApi: OriginationReadApi) {}

  //  ____   ___  ____ _____
  // |  _ \ / _ \/ ___|_   _|
  // | |_) | | | \___ \ | |
  // |  __/| |_| |___) || |
  // |_|    \___/|____/ |_|

  //   ____ _____ _____
  //  / ___| ____|_   _|
  // | |  _|  _|   | |
  // | |_| | |___  | |
  //  \____|_____| |_|

  // loadBankList() {
  //   return this.readApi.getBankList().then(res => {
  //     DataStore.bankList = res;
  //     return DataStore.bankList;
  //   });
  // }

  loadBankListWithDetails() {
    this.setBankListWithDetails(null);
    return this.readApi
      .getBankListDetails()
      .then(res => {
        this.setBankListWithDetails(res);
        return res;
      })
      .catch(err => {
        this.setBankListWithDetails(null);
        return null;
      });
  }

  //  ____  _____ _____
  // / ___|| ____|_   _|
  // \___ \|  _|   | |
  //  ___) | |___  | |
  // |____/|_____| |_|

  setBankListWithDetails(data: any[]) {
    DataStore.bankList = data;
  }

  resetLoadingPromises() {
    DataStore.loadingPromises = [];
    DataStore.loadingCallbackUrl = null;
  }

  setLoadingCallbackUrl(data: string) {
    DataStore.loadingCallbackUrl = data;
  }

  addLoadingPromise(data: Promise<any>) {
    DataStore.loadingPromises.push(data);
  }
}
