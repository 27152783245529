import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { environment } from '@env/environment';
import { AppNavigatorService } from '@app/core/services/app-navigator.service';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/internal/operators';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { REGION } from '@app/Constants';
import { SecurityContext } from '@app/core/interfaces/i-security-context';
import { UserService } from '@app/core/services/user.service';

const CORPRATE_CARDHOLDER = 'corpratecardholder';
const EMPLOYEE = 'employee';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  version: string = environment.version;
  error: string;
  loginForm: FormGroup;
  menuHidden = true;
  isLoading = false;
  isUserTypeEmployee = false;
  isUserTypeCorprate = false;
  userLoading = false;
  loggedInLoading = false;
  hasLoggedIn = false;
  defaultRegion = window['appConfig']['defaultRegion'];
  isUSRegion = this.defaultRegion == REGION.US;
  loginButtonText = 'Login to your account';
  isRedirecting = false;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private NavService: AppNavigatorService,
    private userService: UserService
  ) {}

  ngOnInit() {
    sessionStorage.setItem('isSms', 'false');
    localStorage.removeItem('announcement');
    this.createForm();
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  login() {
    // this.loaderService.setStatus(true);
    this.isLoading = true;

    this.authenticationService
      .login(this.loginForm.value, true)
      .pipe(
        switchMap(credentials =>
          this.authenticationService.requiresTwoWayAuth(credentials)
            ? this.router.navigate(['/two-factor'])
            : this.postLoginActions(credentials)
        )
      )
      .subscribe(
        _ => {},
        err => {
          this.error = 'Login Incorrect';
          if (err.toString()) {
            const errors = err.toString().split('Error: ');
            if (errors.length > 0) {
              if (
                errors[1] &&
                /^[\],:{}\s]*$/.test(
                  errors[1]
                    .replace(/\\["\\\/bfnrtu]/g, '@')
                    .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']')
                    .replace(/(?:^|:|,)(?:\s*\[)+/g, '')
                )
              ) {
                const parseMessage = JSON.parse(errors[1]);
                if (typeof parseMessage === 'string') {
                  this.error = parseMessage;
                  if (this.error.includes('Your password has expired and must be changed.')) {
                    localStorage.setItem('e', this.loginForm.value.username);
                    this.loginButtonText = 'Redirecting...';
                    this.isRedirecting = true;
                    setTimeout(() => {
                      this.router.navigate(['/password-expires']);
                    }, 3000);
                  }
                }
              }
            }
          }
          this.loginForm.markAsPristine();
          // this.loaderService.setStatus(false);
          this.isLoading = false;
        }
      );
  }

  /*
  onBlur() {
    if (this.isUSRegion && this.loginForm.value.username) {
      this.userLoading = true;
      this.isEmployee();
    }
  }*/

  trim(event) {
    this.loginForm.get('username').setValue(event.currentTarget.value.trim());
  }

  /*
  isEmployee() {
    this.employeeService
      .isEmployee({ username: this.loginForm.value.username })
      .then(res => {
        this.isUserTypeEmployee = res;
        if (this.isUserTypeEmployee) {
          this.loginForm.controls['password'].disable();
          this.hasEmployeeLoggedIn();
        } else {
          this.isCorprate();
        }
      })
      .finally(() => (this.userLoading = false));
  }*/

  /*
  hasEmployeeLoggedIn() {
    this.loggedInLoading = true;
    this.employeeService
      .hasEmployeeLoggedIn({ username: this.loginForm.value.username })
      .then(res => {
        this.loginForm.controls['password'].enable();
        this.hasLoggedIn = res;
        if (!this.hasLoggedIn) {
          localStorage.setItem(
            'firstLogin',
            JSON.stringify({
              userName: this.loginForm.value.username,
              userType: EMPLOYEE
            })
          );
          this.router.navigate(['/first-login'], { replaceUrl: true });
        }
      })
      .finally(() => (this.loggedInLoading = false));
  }

  isCorprate() {
    this.corprateService
      .isCorprate({ username: this.loginForm.value.username })
      .then(res => {
        this.isUserTypeCorprate = res;
        if (this.isUserTypeCorprate) {
          this.loginForm.controls['password'].disable();
          this.hasCorprateLoggedIn();
        }
      })
      .finally(() => (this.userLoading = false));
  }

  hasCorprateLoggedIn() {
    this.loggedInLoading = true;
    this.corprateService
      .hasCorprateLoggedIn({ username: this.loginForm.value.username })
      .then(res => {
        this.loginForm.controls['password'].enable();
        this.hasLoggedIn = res;
        if (!this.hasLoggedIn) {
          localStorage.setItem(
            'firstLogin',
            JSON.stringify({
              userName: this.loginForm.value.username,
              userType: CORPRATE_CARDHOLDER
            })
          );
          this.router.navigate(['/first-login'], { replaceUrl: true });
        }
      })
      .finally(() => (this.loggedInLoading = false));
  }*/

  postLoginActions(credentials: SecurityContext): Observable<any> {
    if (credentials.userType.toLowerCase() === 'agent') {
      return from(
        this.userService
          .loadAgentHomepageData(credentials.id)
          .then(_ => {
            return this.router.navigate(['/agent/home'], { replaceUrl: true });
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else if (credentials.userType.toLowerCase() === 'partner') {
      localStorage.setItem('partnerAccountConnected', 'false');
      return from(
        this.userService
          .loadPartnerHomepageData(credentials.id)
          .then(_ => {
            return this.router.navigate(['/partner/home'], { replaceUrl: true });
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else if (credentials.userType.toLowerCase() === 'customer' || credentials.userType.toLowerCase() === 'admin') {
      return from(
        this.userService
          .loadCustomerForHomePage(credentials.id)
          .then(_ => {
            return this.NavService.navigateToCustomerHome();
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else {
      /* else if (credentials.userType.toLowerCase() === EMPLOYEE) {
      return from(
        this.employeeService
          .loadEmployeeForHomePage(credentials.id)
          .then(_ => {
            return this.router.navigate(['/employee/home'], { replaceUrl: true });
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else if (credentials.userType.toLowerCase() === CORPRATE_CARDHOLDER) {
      return from(
        this.corprateService
          .loadCorprateForHomePage(credentials.id)
          .then(_ => {
            return this.router.navigate(['/corprate/home'], { replaceUrl: true });
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } */
      throw new Error('User not allowed');
    }
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      username: [localStorage.getItem('userName') || '', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      remember: true
    });
  }
}
