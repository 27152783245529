import { Component, OnInit } from '@angular/core';
import { OriginationReadApi } from '../../../core/proxy/Read/originationReadApi';
import { ApplicationService } from '@app/core/services/application.service';
import { DataStore } from '@app/DataStore';

@Component({
  selector: 'app-system-notification',
  templateUrl: './system-notification.component.html',
  styleUrls: ['./system-notification.component.scss']
})
export class SystemNotificationComponent implements OnInit {
  get message() {
    return DataStore.systemMessage;
  }
  constructor(private applicationService: ApplicationService) {
    this.loadSystemMessage();
  }

  ngOnInit() {}

  private loadSystemMessage() {
    this.applicationService.getSystemMessage();
  }
}
