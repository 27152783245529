import { ToastrService } from 'ngx-toastr';
import { OriginationWriteApi } from '@app/core/proxy/Write/originationWriteApi';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IpService } from './../../../core/services/ip.service';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/core/services/authentication.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  version: string = environment.version;
  error: string;
  forgotPasswordForm: FormGroup;
  isLoading = false;
  menuHidden = true;
  isSmsLogin = false;
  failed = false;
  digit1;
  digit2;
  digit3;
  digit4;
  ipAddress: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ipservice: IpService,
    private formBuilder: FormBuilder,
    private writeApi: OriginationWriteApi,
    private toastrService: ToastrService,
    private authenticationService: AuthenticationService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.ipservice.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }
  forgotPassword() {
    this.isLoading = true;
    this.writeApi
      .sendResetPasswordOtp({ email: this.forgotPasswordForm.value.email })
      .then(() => {
        this.isSmsLogin = true;
        setTimeout(() => {
          const e = document.getElementById('digit-1');
          e.focus();
        }, 500);
      })
      .catch(_ => {
        this.toastrService.error('Failed to send Code.');
      })
      .finally(() => (this.isLoading = false));
  }
  resend() {
    this.forgotPassword();
    this.digit1 = this.digit2 = this.digit3 = this.digit4 = '';
    this.failed = false;
  }

  onKeyDown(event: any) {
    if ((event.which > 47 && event.which < 58) || (event.which >= 96 && event.which <= 105)) {
      event.currentTarget.value = '';
    }
  }

  onKeyUp(event: any, nextElemId?: string, prevElemId?: string) {
    if (event.which === 8 && prevElemId) {
      const e = document.getElementById(prevElemId);
      e.focus();
    } else if (((event.which > 47 && event.which < 58) || (event.which >= 96 && event.which <= 105)) && nextElemId) {
      const e = document.getElementById(nextElemId);
      e.focus();
    }
  }

  get digitsFilled() {
    return this.digit1 && this.digit2 && this.digit3 && this.digit4;
  }

  goToComplete(event?: any) {
    if (event && event.which === 8) {
      return;
    }

    if (!this.digitsFilled) {
      this.toastrService.error('Please fill code first.');
      return;
    }

    this.failed = false;
    this.isLoading = true;
    const code = '' + this.digit1 + this.digit2 + this.digit3 + this.digit4;

    const urlInfo = {
      code,
      email: this.forgotPasswordForm.value.email
    };

    this.authenticationService
      .validateTwoWayAuth({ username: urlInfo.email, code, ipAddress: this.ipAddress })
      .subscribe(
        _ => {
          localStorage.setItem('userInfo', JSON.stringify(urlInfo));
          this.router.navigate(['/change-password']);
        },
        _ => {
          this.failed = true;
          this.isLoading = false;
        }
      );
  }

  private createForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', Validators.required]
    });
  }
}
