import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { AppNavigatorService } from 'app/core/services/app-navigator.service';
import { Observable } from 'rxjs';
import { AuthenticationService } from '@app/core/services/authentication.service';

/**
 *  This guard is responsible for checking whether a Partner is connected
 *  and its details are available at the DataStore.
 */
@Injectable({ providedIn: 'root' })
export class PartnerAuthenticationGuard implements CanLoad {
  constructor(
    private router: Router,
    private AuthService: AuthenticationService,
    private AppNavigator: AppNavigatorService
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.AuthService.isPartner()) {
      this.AppNavigator.navigateLogin();
      return false;
    }

    return true;
  }
}
