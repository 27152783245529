/*
 * Entry point of the application.
 * Only platform bootstrapping code should be here.
 * For app-specific initialization, use `app/app.component.ts`.
 */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

function bootstrapFailed(val: any) {
  console.error('bootstrap-fail', val);
}

fetch('./environments/environment.json')
  .then(response => response.json())
  .then(config => {
    if (!config) {
      bootstrapFailed(config);
      return;
    }

    if (environment.production) {
      enableProdMode();
    }
    // Store the response somewhere that your ConfigService can read it.
    window['appConfig'] = config;

    platformBrowserDynamic()
      .bootstrapModule(AppModule)
      .catch(bootstrapFailed);
  })
  .catch(bootstrapFailed);

(function() {
  window.onerror = function(err) {
    console.log(arguments);
  };
})();
