<app-public-header [showLoginButton]="true" [showCancelButton]="false" [addMobileCss]="true" class="d-block">
</app-public-header>
<div
  class="bg-image-gradient height-100vh with-header overflow-auto p-3 p-sm-5 d-flex flex-column align-items-center justify-content-center"
>
  <!-- CONTENT -->
  <div class="w-100 widescreen-scale">
    <ng-container *ngIf="!passwordResetComplete">
      <h1 class="mb-1 text-center font-weight-bold">Change password</h1>
      <div class="d-flex text-center" style="justify-content: center;">
        <p class="alert alert-danger font-weight-bold">Your password has expired and must be changed.</p>
      </div>
      <form (ngSubmit)="resetPassword()" [formGroup]="passwordExpiresForm" novalidate>
        <div
          class="d-flex flex-column py-0 flex-spacer mt-5 rounded-xl bg-white p-3 p-sm-5 mx-auto box-shadow"
          style="max-width: 500px;"
        >
          <div class="form-group flex-spacer">
            <div class="mb-4 position-relative">
              <label class="d-block text-uppercase font-weight-bold mb-1 text s-x04 plexe-label-text"
                >Current Password</label
              >
              <input
                type="password"
                class="plexe-form-field bg-white pr-4"
                formControlName="currentPassword"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$"
              />
              <img
                class="position-absolute"
                style="top: 28px; right: 5px;"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="passwordExpiresForm.controls.currentPassword.valid"
              />
              <span hidden translate>Old Password</span>
              <small
                [hidden]="
                  passwordExpiresForm.controls.currentPassword.valid ||
                  passwordExpiresForm.controls.currentPassword.untouched
                "
                class="text-danger"
                translate
              >
                <div
                  class="text-left"
                  *ngIf="
                    passwordExpiresForm.controls.currentPassword.errors &&
                    passwordExpiresForm.controls.currentPassword.errors.required
                  "
                >
                  Old password is required
                </div>
                <div
                  class="text-left"
                  *ngIf="
                    passwordExpiresForm.controls.currentPassword.errors &&
                    passwordExpiresForm.controls.currentPassword.errors.pattern
                  "
                >
                  Old password is invalid. Password must contain minimum 8 characters, including 1 digit, 1 uppercase &
                  lowercase letter.
                </div>
              </small>
            </div>
            <div class="mb-4 position-relative">
              <label class="d-block text-uppercase font-weight-bold mb-1 text s-x04 plexe-label-text"
                >New Password</label
              >
              <input
                type="password"
                class="plexe-form-field bg-white pr-4"
                formControlName="password"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$"
              />
              <img
                class="position-absolute"
                style="top: 28px; right: 5px;"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="passwordExpiresForm.controls.password.valid"
              />
              <span hidden translate>Password</span>
              <small
                [hidden]="
                  passwordExpiresForm.controls.password.valid || passwordExpiresForm.controls.password.untouched
                "
                class="text-danger"
                translate
              >
                <div
                  class="text-left"
                  *ngIf="
                    passwordExpiresForm.controls.password.errors &&
                    passwordExpiresForm.controls.password.errors.required
                  "
                >
                  Password is required
                </div>
                <div
                  class="text-left"
                  *ngIf="
                    passwordExpiresForm.controls.password.errors && passwordExpiresForm.controls.password.errors.pattern
                  "
                >
                  Password is invalid. Password must contain minimum 8 characters, including 1 digit, 1 uppercase &
                  lowercase letter.
                </div>
              </small>
            </div>
            <div class="position-relative">
              <label class="d-block text-uppercase font-weight-bold mb-1 text s-x04 plexe-label-text"
                >Confirm Password</label
              >
              <input type="password" class="plexe-form-field bg-white pr-4" formControlName="passwordConfirmation" />
              <img
                class="position-absolute"
                style="top: 28px; right: 5px;"
                src="assets/svgs/broker/check_yellow.svg"
                *ngIf="passwordExpiresForm.controls.passwordConfirmation.valid"
              />
              <span hidden translate>Confirm Password</span>
              <small
                [hidden]="passwordExpiresForm.controls.passwordConfirmation.untouched"
                class="text-danger"
                translate
              >
                <div
                  class="text-left"
                  *ngIf="
                    passwordExpiresForm.controls.passwordConfirmation.errors &&
                    passwordExpiresForm.controls.passwordConfirmation.errors.required
                  "
                >
                  Confirm Password is required
                </div>
                <div
                  class="text-left"
                  *ngIf="
                    passwordExpiresForm.controls.password.touched &&
                    passwordExpiresForm.invalid &&
                    passwordExpiresForm.errors &&
                    passwordExpiresForm.errors.notSame &&
                    !(
                      passwordExpiresForm.controls.passwordConfirmation.errors &&
                      passwordExpiresForm.controls.passwordConfirmation.errors.required
                    )
                  "
                >
                  Passwords do not match
                </div>
              </small>
            </div>
          </div>
        </div>
        <div class="mt-5 text-center text-sm-left">
          <button
            class="plexe-btn-primary d-block mx-auto width-220 h-auto px-3 py-2 mb-3"
            type="submit"
            [disabled]="passwordExpiresForm.invalid || isLoading"
          >
            <i class="fa fa-spinner fa-spin mr-1" aria-hidden="true" [hidden]="!isLoading"></i>
            <span translate>Set new password</span>
          </button>
          <a
            class="d-block forgot-password-link text-decoration-underline text-black text-center font-weight-bold"
            routerLink="/login"
            >Login here</a
          >
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="passwordResetComplete">
      <h1 class="mb-1 text-center text-white font-weight-bold">Password reset completed.</h1>
      <div class="mt-5 text-center text-sm-left">
        <button
          class="plexe-btn-primary d-block mx-auto width-220 h-auto px-3 py-2 mb-3"
          type="button"
          (click)="okButton()"
          [disabled]="isLoggingIn"
        >
          <i class="fa fa-spinner fa-spin mr-1" aria-hidden="true" [hidden]="!isLoggingIn"></i>
          <span translate>OK</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>
