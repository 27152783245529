<app-public-header [showLoginButton]="true" [showCancelButton]="false"></app-public-header>

<div class="content-wrapper">
  <app-loader></app-loader>

  <div class="pages-container bg-light">
    <div class="container">
      <div class="inner-content">
        <div>
          <h1 class="mb-3">
            SMS verification required
          </h1>
        </div>
        <div class="card-wrapper row">
          <p class="card-text mb-0">
            A message with a verification code has been sent to your phone.
          </p>
          <p class="card-text mb-0">
            Please enter the code to continue.
          </p>
        </div>
        <div class="sms-code-input-wrapper">
          <div class="d-flex">
            <input
              type="text"
              class="form-control sms-code-input"
              maxlength="1"
              id="digit-1"
              mask="0"
              [(ngModel)]="digit1"
              (keydown)="onKeyDown($event)"
              (keyup)="onKeyUp($event, 'digit-2')"
            />
            <input
              type="text"
              class="form-control sms-code-input"
              maxlength="1"
              id="digit-2"
              [(ngModel)]="digit2"
              mask="0"
              (keydown)="onKeyDown($event)"
              (keyup)="onKeyUp($event, 'digit-3')"
            />
            <input
              type="text"
              class="form-control sms-code-input"
              maxlength="1"
              id="digit-3"
              [(ngModel)]="digit3"
              mask="0"
              (keydown)="onKeyDown($event)"
              (keyup)="onKeyUp($event, 'digit-4')"
            />
          </div>
          <div class="dash"></div>
          <div class="d-flex">
            <input
              type="text"
              class="form-control sms-code-input"
              maxlength="1"
              id="digit-4"
              [(ngModel)]="digit4"
              mask="0"
              (keydown)="onKeyDown($event)"
              (keyup)="onKeyUp($event, 'digit-5')"
            />
            <input
              type="text"
              class="form-control sms-code-input"
              maxlength="1"
              id="digit-5"
              [(ngModel)]="digit5"
              mask="0"
              (keydown)="onKeyDown($event)"
              (keyup)="onKeyUp($event, 'digit-6')"
            />
            <input
              type="text"
              class="form-control sms-code-input"
              maxlength="1"
              id="digit-6"
              [(ngModel)]="digit6"
              mask="0"
              (keydown)="onKeyDown($event)"
              (keyup)="onKeyUp($event)"
            />
          </div>
        </div>
        <div class="mt-4">
          <!--<p class="plexe-dark-blue text-center">-->
          <!--<a class="plexe-light-blue text-decoration-underline curs-pointer" (click)="resendSMS()">Resend SMS Code</a>-->
          <!--</p>-->
          <small class="text-danger" *ngIf="failed">Failed to validate code</small>
          <div class="mt-40">
            <button class="plexe-btn-primary" [disabled]="!digitsFilled" (click)="proceed()">
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
