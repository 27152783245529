import { ToastrService } from 'ngx-toastr';
import { OriginationWriteApi } from 'app/core/proxy/Write/originationWriteApi';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  state: any = [];
  passwordForm: FormGroup;
  error: string;
  isLoading = false;

  passwordMatch = true;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private writeApi: OriginationWriteApi,
    private toastrService: ToastrService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.state = JSON.parse(localStorage.getItem('userInfo'));
    if (!this.state) this.router.navigate(['/login']);
  }
  private createForm() {
    this.passwordForm = this.formBuilder.group({
      newpassword: ['', Validators.required],
      confirmpassword: ['', Validators.required]
    });
  }
  onBlur() {
    const group = this.passwordForm.controls;
    if (group.newpassword.value != group.confirmpassword.value) {
      this.passwordMatch = false;
    } else {
      this.passwordMatch = true;
    }
  }

  resetPassword() {
    this.isLoading = true;
    const request = {
      userName: this.state.email,
      password: this.passwordForm.controls.newpassword.value,
      passwordConfirmation: this.passwordForm.controls.confirmpassword.value
    };

    this.writeApi
      .changePassword({ body: request })
      .then(_ => {
        this.toastrService.success('Password has been changed successfully.');
        this.router.navigate(['/login']);
      })
      .catch(err => {
        this.isLoading = false;
        console.log(err);
      });
  }
  ngOnDestroy() {
    localStorage.removeItem('userInfo');
  }
}
