<div class="bg-image-gradient h-100 overflow-auto">
  <div class="d-none d-sm-flex ">
    <div class="content-wrapper_registration px-4 py-2 px-sm-5 height-100vh with-header w-100 overflow-auto">
      <div class="container_registration minh-xs-100 d-flex flex-column py-0 justify-content-center">
        <!--<div class="d-block d-sm-none text-center">
        test
        <a class="navbar-brand m_logo mt-4 mb-4" routerLink="/" translate>
         
        </a>
      </div>-->

        <div class="d-none d-sm-flex rounded-2xl h-100 m-auto w-100 public-page-content">
          <!-- Public menu -->
          <div class="mr-4 d-none d-sm-none d-md-block d-lg-block d-xl-block" style="width: 270px; flex: 0 0 270px;">
            <app-public-sidemenu
              [noMenuItem]="noMenuItem"
              [isBottomLink]="isSidemenuBottomLink"
              [isFaq]="isSidemenuFaq"
            ></app-public-sidemenu>
          </div>

          <div class="rounded-2xl bg-white d-flex flex-spacer overflow-auto box-shadow">
            <div
              class="bg-white rounded-2xl p-4 flex-spacer box-shadow d-flex flex-column overflow-auto zindex-1 box-shadow"
            >
              <ng-content select="[public-pages-content]"></ng-content>
            </div>
            <div class="bg-black opacity-6 position-absolute top-0 left-0 bottom-0 right-0" *ngIf="isBackdrop"></div>
          </div>

          <app-customer-communication-panel
            *ngIf="isCommunicationPanel"
            [type]="customerType"
          ></app-customer-communication-panel>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex d-sm-none">
    <div class="content-wrapper_registration px-4 py-2 px-sm-5 height-100vh w-100 overflow-auto">
      <div class="container_registration minh-xs-100 d-flex flex-column py-0 justify-content-center">
        <!-- Mobile -->
        <div class="d-flex d-sm-none flex-column flex-spacer pt-2 pb-3">
          <div class="d-flex flex-column flex-spacer">
            <div
              class="bg-white rounded-2xl p-4 flex-spacer box-shadow d-flex flex-column overflow-auto zindex-1"
              [ngClass]="noMobileBox ? '' : 'bg-white'"
            >
              <ng-content select="[public-pages-mobile-content]"></ng-content>
            </div>
          </div>
          <ng-content select="[mobile-bottom-link]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
