import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgCustomTypeahead } from '@app/components/ng-custom-typeahead/ng-custom-typeahead';

export const options: Partial<any> | (() => Partial<any>) = {};
@NgModule({
  imports: [CommonModule, NgbModule, FormsModule],
  declarations: [NgCustomTypeahead],
  exports: [NgCustomTypeahead]
})
export class NgbCustomOverridesModule {}
