import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { environment } from '@env/environment';
import { AuthorizationAPI } from '@app/core/proxy/Auth/authorizationAPI';
import { markFormGroupTouched } from '@app/PlexeUtils';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  version: string = environment.version;
  error: string;
  forgotPasswordForm: FormGroup;
  isLoading = false;
  menuHidden = true;
  token: string;
  email: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private AuthApi: AuthorizationAPI
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.token = this.route.snapshot.queryParamMap.get('c');
    this.email = this.route.snapshot.queryParamMap.get('e');
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  resetPassword() {
    if (this.forgotPasswordForm.invalid) {
      markFormGroupTouched(this.forgotPasswordForm);
      return;
    }

    if (!this.token) {
      this.toastrService.error('No Reset Password token found');
      return;
    }

    if (!this.email) {
      this.toastrService.error('No Reset Password email found');
      return;
    }

    this.isLoading = true;

    this.AuthApi.passwordResetConfirmation(this.token, {
      userEmail: this.email,
      body: this.forgotPasswordForm.value
    })
      .then(() => {
        this.toastrService.success('Password reset successfully');
        this.router.navigate(['/login'], { replaceUrl: true });
      })
      .catch(err => {
        this.toastrService.error('Failed to reset password');
      })
      .then(() => (this.isLoading = false));
  }

  checkPasswords(group: FormGroup) {
    if (!group) {
      return;
    }
    const pass = group.controls.password.value;
    const confirmPass = group.controls.passwordConfirmation.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  private createForm() {
    this.forgotPasswordForm = this.formBuilder.group(
      {
        password: ['', Validators.required],
        passwordConfirmation: ['', Validators.required]
      },
      { validators: [this.checkPasswords] }
    );
  }
}
