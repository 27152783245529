import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { convertNgbDate, markFormGroupTouched } from '@app/PlexeUtils';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-first-login',
  templateUrl: './first-login.component.html',
  styleUrls: ['./first-login.component.scss']
})
export class FirstLoginComponent implements OnInit {
  version: string = environment.version;
  error: string;
  resetPasswordForm: FormGroup;
  isLoading = false;
  menuHidden = true;
  firstLogin: any;

  constructor(private router: Router, private formBuilder: FormBuilder, private toastrService: ToastrService) {
    this.createForm();
  }

  ngOnInit() {
    this.firstLogin = JSON.parse(localStorage.getItem('firstLogin'));
    if (!this.firstLogin) {
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  resetPassword() {
    if (this.resetPasswordForm.invalid) {
      markFormGroupTouched(this.resetPasswordForm);
      return;
    }

    this.isLoading = true;
  }

  checkPasswords(group: FormGroup) {
    if (!group) {
      return;
    }
    const pass = group.controls.password.value;
    const confirmPass = group.controls.passwordConfirmation.value;

    return pass === confirmPass ? null : { notSame: true };
  }

  private createForm() {
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: ['', Validators.required],
        passwordConfirmation: ['', Validators.required],
        dateOfBirth: ['', Validators.required]
      },
      { validators: [this.checkPasswords] }
    );
  }

  ngOnDestroy() {
    localStorage.removeItem('userName');
  }
}
