<div class="error-container bg-light">
  <div class="content">
    <a class="navbar-brand" routerLink="/">
      <!--<img [src]="plexeLogoUrl" alt="plexe" />-->
    </a>
    <div class="error-content">
      <h3 class="more-bold mb-3">We are currently processing your application</h3>
      <p class="plexe-dark-blue">Please check your email for details or contact your Broker</p>
      <button class="mt-3 plexe-btn-primary" routerLink="/landing">Go Home</button>
    </div>
  </div>
</div>
