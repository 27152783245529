import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-pages-template',
  templateUrl: './public-pages-template.component.html',
  styleUrls: ['./public-pages-template.component.scss']
})
export class PublicPagesTemplateComponent implements OnInit {
  @Input() noMobileBox = false;
  @Input() noMenuItem = false;
  @Input() noFullHeightMobileBox = false;
  @Input() isSidemenuBottomLink = false;
  @Input() isSidemenuFaq = false;
  @Input() isBackdrop = false;
  @Input() isCommunicationPanel = false;
  @Input() customerType = 'application';
  @Input() isBankSettings = false;

  constructor() {}

  ngOnInit() {}
}
