import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private _title: Subject<string> = new Subject();
  private title: string;

  constructor() {}

  //  ____   ___  ____ _____
  // |  _ \ / _ \/ ___|_   _|
  // | |_) | | | \___ \ | |
  // |  __/| |_| |___) || |
  // |_|    \___/|____/ |_|

  //   ____ _____ _____
  //  / ___| ____|_   _|
  // | |  _|  _|   | |
  // | |_| | |___  | |
  //  \____|_____| |_|

  getTitle() {
    return this.title;
  }

  subscribeTitle() {
    return this._title.asObservable();
  }

  //  ____  _____ _____
  // / ___|| ____|_   _|
  // \___ \|  _|   | |
  //  ___) | |___  | |
  // |____/|_____| |_|

  setTitle(text: string) {
    this.title = text;
    this._title.next(text);
  }
}
