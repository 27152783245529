/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from '@azure/ms-rest-js';

export const accounting: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'accounting'],
  mapper: {
    serializedName: 'accounting',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const address: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'address'],
  mapper: {
    serializedName: 'address',
    type: {
      name: 'String'
    }
  }
};
export const advanceId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'advanceId'],
  mapper: {
    serializedName: 'advanceId',
    type: {
      name: 'Uuid'
    }
  }
};
export const advanceId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'advanceId'],
  mapper: {
    serializedName: 'AdvanceId',
    type: {
      name: 'Uuid'
    }
  }
};
export const advanceId2: msRest.OperationURLParameter = {
  parameterPath: 'advanceId',
  mapper: {
    required: true,
    serializedName: 'advanceId',
    type: {
      name: 'Uuid'
    }
  }
};
export const advanceRate: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'advanceRate'],
  mapper: {
    serializedName: 'advanceRate',
    type: {
      name: 'Number'
    }
  }
};
export const agentId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'agentId'],
  mapper: {
    serializedName: 'agentId',
    type: {
      name: 'Uuid'
    }
  }
};
export const agentId1: msRest.OperationURLParameter = {
  parameterPath: 'agentId',
  mapper: {
    required: true,
    serializedName: 'agentId',
    type: {
      name: 'Uuid'
    }
  }
};
export const alert: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'alert'],
  mapper: {
    serializedName: 'alert',
    type: {
      name: 'String'
    }
  }
};
export const allowChange: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'allowChange'],
  mapper: {
    serializedName: 'allowChange',
    type: {
      name: 'Boolean'
    }
  }
};
export const allowModification: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'allowModification'],
  mapper: {
    serializedName: 'allowModification',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const amount: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'amount'],
  mapper: {
    serializedName: 'amount',
    type: {
      name: 'Number'
    }
  }
};
export const announcementId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'announcementId'],
  mapper: {
    serializedName: 'announcementId',
    type: {
      name: 'Uuid'
    }
  }
};
export const answer: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'answer'],
  mapper: {
    serializedName: 'answer',
    type: {
      name: 'Boolean'
    }
  }
};
export const applicant: msRest.OperationURLParameter = {
  parameterPath: 'applicant',
  mapper: {
    required: true,
    serializedName: 'applicant',
    type: {
      name: 'String'
    }
  }
};
export const applicantId0: msRest.OperationQueryParameter = {
  parameterPath: 'applicantId',
  mapper: {
    required: true,
    serializedName: 'applicantId',
    type: {
      name: 'Uuid'
    }
  }
};
export const applicantId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'applicantId'],
  mapper: {
    serializedName: 'applicantId',
    type: {
      name: 'Uuid'
    }
  }
};
export const applicationId0: msRest.OperationURLParameter = {
  parameterPath: 'applicationId',
  mapper: {
    required: true,
    serializedName: 'applicationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const applicationId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'applicationId'],
  mapper: {
    serializedName: 'applicationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const archive: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'archive'],
  mapper: {
    serializedName: 'archive',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const balanceOverride: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'balanceOverride'],
  mapper: {
    serializedName: 'balanceOverride',
    type: {
      name: 'Boolean'
    }
  }
};
export const bankId: msRest.OperationQueryParameter = {
  parameterPath: 'bankId',
  mapper: {
    required: true,
    serializedName: 'bankId',
    type: {
      name: 'String'
    }
  }
};
export const banking: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'banking'],
  mapper: {
    serializedName: 'banking',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const businessName: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'businessName'],
  mapper: {
    serializedName: 'businessName',
    type: {
      name: 'String'
    }
  }
};
export const cancelReasonId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'cancelReasonId'],
  mapper: {
    serializedName: 'cancelReasonId',
    type: {
      name: 'Uuid'
    }
  }
};
export const chargeOffDate: msRest.OperationQueryParameter = {
  parameterPath: 'chargeOffDate',
  mapper: {
    required: true,
    serializedName: 'chargeOffDate',
    type: {
      name: 'DateTime'
    }
  }
};
export const checkListId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'checkListId'],
  mapper: {
    serializedName: 'checkListId',
    type: {
      name: 'Uuid'
    }
  }
};
export const city: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'city'],
  mapper: {
    serializedName: 'city',
    type: {
      name: 'String'
    }
  }
};
export const classification0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'classification'],
  mapper: {
    serializedName: 'classification',
    defaultValue: '',
    type: {
      name: 'String'
    }
  }
};
export const classification1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'classification'],
  mapper: {
    serializedName: 'classification',
    type: {
      name: 'String'
    }
  }
};
export const code: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'code'],
  mapper: {
    serializedName: 'code',
    type: {
      name: 'String'
    }
  }
};
export const comment: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'comment'],
  mapper: {
    serializedName: 'comment',
    type: {
      name: 'String'
    }
  }
};
export const communicationId0: msRest.OperationURLParameter = {
  parameterPath: 'communicationId',
  mapper: {
    required: true,
    serializedName: 'communicationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const communicationId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'communicationId'],
  mapper: {
    serializedName: 'communicationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const companies: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'companies'],
  mapper: {
    serializedName: 'companies',
    type: {
      name: 'String'
    }
  }
};
export const companyUri: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'companyUri'],
  mapper: {
    serializedName: 'companyUri',
    type: {
      name: 'String'
    }
  }
};
export const connectionId: msRest.OperationURLParameter = {
  parameterPath: 'connectionId',
  mapper: {
    required: true,
    serializedName: 'connectionId',
    type: {
      name: 'String'
    }
  }
};
export const contactId: msRest.OperationURLParameter = {
  parameterPath: 'contactId',
  mapper: {
    required: true,
    serializedName: 'contactId',
    type: {
      name: 'Uuid'
    }
  }
};
export const count: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'count'],
  mapper: {
    serializedName: 'count',
    type: {
      name: 'Number'
    }
  }
};
export const createdOnUtc: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'createdOnUtc'],
  mapper: {
    serializedName: 'createdOnUtc',
    type: {
      name: 'DateTime'
    }
  }
};
export const currentProcess: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'currentProcess'],
  mapper: {
    serializedName: 'currentProcess',
    type: {
      name: 'String'
    }
  }
};
export const customer: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'customer'],
  mapper: {
    serializedName: 'customer',
    type: {
      name: 'String'
    }
  }
};
export const customerId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'customerId'],
  mapper: {
    serializedName: 'customerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const customerId1: msRest.OperationQueryParameter = {
  parameterPath: 'customerId',
  mapper: {
    required: true,
    serializedName: 'customerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const dateOfBirth: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'dateOfBirth'],
  mapper: {
    serializedName: 'dateOfBirth',
    type: {
      name: 'DateTime'
    }
  }
};
export const dateParameter: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'dateParameter'],
  mapper: {
    serializedName: 'date',
    type: {
      name: 'DateTime'
    }
  }
};
export const dateTime: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'dateTime'],
  mapper: {
    serializedName: 'dateTime',
    type: {
      name: 'DateTime'
    }
  }
};
export const day: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'day'],
  mapper: {
    serializedName: 'day',
    type: {
      name: 'Number'
    }
  }
};
export const days: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'days'],
  mapper: {
    serializedName: 'days',
    defaultValue: 5,
    type: {
      name: 'Number'
    }
  }
};
export const debtor: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'debtor'],
  mapper: {
    serializedName: 'debtor',
    type: {
      name: 'String'
    }
  }
};
export const debtorId0: msRest.OperationQueryParameter = {
  parameterPath: 'debtorId',
  mapper: {
    required: true,
    serializedName: 'debtorId',
    type: {
      name: 'String'
    }
  }
};
export const debtorId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'debtorId'],
  mapper: {
    serializedName: 'debtorId',
    type: {
      name: 'String'
    }
  }
};
export const debtors: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'debtors'],
  mapper: {
    serializedName: 'debtors',
    type: {
      name: 'String'
    }
  }
};
export const defaulted: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'defaulted'],
  mapper: {
    serializedName: 'defaulted',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const depositDate: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'depositDate'],
  mapper: {
    serializedName: 'depositDate',
    type: {
      name: 'DateTime'
    }
  }
};
export const destinationId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'destinationId'],
  mapper: {
    serializedName: 'destinationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const details: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'details'],
  mapper: {
    serializedName: 'details',
    type: {
      name: 'String'
    }
  }
};
export const doCheck: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'doCheck'],
  mapper: {
    serializedName: 'doCheck',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const documentId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'documentId'],
  mapper: {
    serializedName: 'documentId',
    type: {
      name: 'String'
    }
  }
};
export const documentId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'documentId'],
  mapper: {
    serializedName: 'documentId',
    type: {
      name: 'Uuid'
    }
  }
};
export const drawFee: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'drawFee'],
  mapper: {
    serializedName: 'drawFee',
    type: {
      name: 'Number'
    }
  }
};
export const drawingFee: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'drawingFee'],
  mapper: {
    serializedName: 'drawingFee',
    type: {
      name: 'Number'
    }
  }
};
export const dryRun: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'dryRun'],
  mapper: {
    serializedName: 'dryRun',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const email0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'email'],
  mapper: {
    serializedName: 'email',
    type: {
      name: 'String'
    }
  }
};
export const email1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'email'],
  mapper: {
    serializedName: 'email',
    type: {
      name: 'Boolean'
    }
  }
};
export const enable0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'enable'],
  mapper: {
    serializedName: 'enable',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const enable1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'enable'],
  mapper: {
    serializedName: 'enable',
    type: {
      name: 'Boolean'
    }
  }
};
export const enabled0: msRest.OperationQueryParameter = {
  parameterPath: 'enabled',
  mapper: {
    required: true,
    serializedName: 'enabled',
    type: {
      name: 'Boolean'
    }
  }
};
export const enabled1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'enabled'],
  mapper: {
    serializedName: 'enabled',
    type: {
      name: 'Boolean'
    }
  }
};
export const enablePlaidMultibank: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'enablePlaidMultibank'],
  mapper: {
    serializedName: 'enablePlaidMultibank',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const endDate: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'endDate'],
  mapper: {
    serializedName: 'endDate',
    type: {
      name: 'DateTime'
    }
  }
};
export const exclude: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'exclude'],
  mapper: {
    serializedName: 'exclude',
    type: {
      name: 'Boolean'
    }
  }
};
export const existingLimit: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'existingLimit'],
  mapper: {
    serializedName: 'existingLimit',
    type: {
      name: 'Number'
    }
  }
};
export const externalSubmitted: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'externalSubmitted'],
  mapper: {
    serializedName: 'externalSubmitted',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const fileData: msRest.OperationParameter = {
  parameterPath: ['options', 'fileData'],
  mapper: {
    serializedName: 'fileData',
    type: {
      name: 'Stream'
    }
  }
};
export const fileDateStr: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'fileDateStr'],
  mapper: {
    serializedName: 'fileDateStr',
    defaultValue: '',
    type: {
      name: 'String'
    }
  }
};
export const filename: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'filename'],
  mapper: {
    serializedName: 'filename',
    type: {
      name: 'String'
    }
  }
};
export const fileName: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'fileName'],
  mapper: {
    serializedName: 'fileName',
    type: {
      name: 'String'
    }
  }
};
export const fileType: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'fileType'],
  mapper: {
    serializedName: 'fileType',
    type: {
      name: 'String'
    }
  }
};
export const firstName: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'firstName'],
  mapper: {
    serializedName: 'firstName',
    type: {
      name: 'String'
    }
  }
};
export const fixedRepayment: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'fixedRepayment'],
  mapper: {
    serializedName: 'fixedRepayment',
    type: {
      name: 'Number'
    }
  }
};
export const fixedRepaymentFee: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'fixedRepaymentFee'],
  mapper: {
    serializedName: 'fixedRepaymentFee',
    type: {
      name: 'Number'
    }
  }
};
export const flag: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'flag'],
  mapper: {
    serializedName: 'flag',
    type: {
      name: 'Boolean'
    }
  }
};
export const flagged: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'flagged'],
  mapper: {
    serializedName: 'flagged',
    type: {
      name: 'Boolean'
    }
  }
};
export const force: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'force'],
  mapper: {
    serializedName: 'force',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const forSecondary: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'forSecondary'],
  mapper: {
    serializedName: 'forSecondary',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const frequency: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'frequency'],
  mapper: {
    serializedName: 'frequency',
    type: {
      name: 'Number'
    }
  }
};
export const fullAllocation: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'fullAllocation'],
  mapper: {
    serializedName: 'fullAllocation',
    type: {
      name: 'Boolean'
    }
  }
};
export const funded: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'funded'],
  mapper: {
    serializedName: 'funded',
    type: {
      name: 'Number'
    }
  }
};
export const funder0: msRest.OperationQueryParameter = {
  parameterPath: 'funder',
  mapper: {
    required: true,
    serializedName: 'funder',
    type: {
      name: 'String'
    }
  }
};
export const funder1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'funder'],
  mapper: {
    serializedName: 'funder',
    type: {
      name: 'String'
    }
  }
};
export const health: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'health'],
  mapper: {
    serializedName: 'health',
    type: {
      name: 'String'
    }
  }
};
export const hide: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'hide'],
  mapper: {
    serializedName: 'hide',
    type: {
      name: 'Boolean'
    }
  }
};
export const host: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'host'],
  mapper: {
    serializedName: 'host',
    type: {
      name: 'String'
    }
  }
};
export const id0: msRest.OperationURLParameter = {
  parameterPath: 'id',
  mapper: {
    required: true,
    serializedName: 'id',
    type: {
      name: 'Number'
    }
  }
};
export const id1: msRest.OperationQueryParameter = {
  parameterPath: 'id',
  mapper: {
    required: true,
    serializedName: 'Id',
    type: {
      name: 'Uuid'
    }
  }
};
export const id2: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'id'],
  mapper: {
    serializedName: 'Id',
    type: {
      name: 'Uuid'
    }
  }
};
export const id3: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'id'],
  mapper: {
    serializedName: 'id',
    type: {
      name: 'Uuid'
    }
  }
};
export const id4: msRest.OperationURLParameter = {
  parameterPath: 'id',
  mapper: {
    required: true,
    serializedName: 'id',
    type: {
      name: 'Uuid'
    }
  }
};
export const interestSplit: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'interestSplit'],
  mapper: {
    serializedName: 'interestSplit',
    type: {
      name: 'Number'
    }
  }
};
export const isDisplayAdvances: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isDisplayAdvances'],
  mapper: {
    serializedName: 'isDisplayAdvances',
    type: {
      name: 'Boolean'
    }
  }
};
export const isEdit: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isEdit'],
  mapper: {
    serializedName: 'isEdit',
    type: {
      name: 'Boolean'
    }
  }
};
export const isEnable: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isEnable'],
  mapper: {
    serializedName: 'isEnable',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const isGeneral: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isGeneral'],
  mapper: {
    serializedName: 'isGeneral',
    type: {
      name: 'Boolean'
    }
  }
};
export const isPartner: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isPartner'],
  mapper: {
    serializedName: 'isPartner',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const isPlaid: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isPlaid'],
  mapper: {
    serializedName: 'isPlaid',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const isRead: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isRead'],
  mapper: {
    serializedName: 'isRead',
    type: {
      name: 'Boolean'
    }
  }
};
export const isSecondary: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isSecondary'],
  mapper: {
    serializedName: 'isSecondary',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const isSms: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isSms'],
  mapper: {
    serializedName: 'isSms',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const isYodlee: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'isYodlee'],
  mapper: {
    serializedName: 'isYodlee',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const lastName: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'lastName'],
  mapper: {
    serializedName: 'lastName',
    type: {
      name: 'String'
    }
  }
};
export const lastRepayment: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'lastRepayment'],
  mapper: {
    serializedName: 'lastRepayment',
    type: {
      name: 'DateTime'
    }
  }
};
export const legacy: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'legacy'],
  mapper: {
    serializedName: 'legacy',
    type: {
      name: 'Boolean'
    }
  }
};
export const licenceNumber: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'licenceNumber'],
  mapper: {
    serializedName: 'licenceNumber',
    type: {
      name: 'String'
    }
  }
};
export const link: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'link'],
  mapper: {
    serializedName: 'link',
    type: {
      name: 'String'
    }
  }
};
export const loanId0: msRest.OperationURLParameter = {
  parameterPath: 'loanId',
  mapper: {
    required: true,
    serializedName: 'loanId',
    type: {
      name: 'Uuid'
    }
  }
};
export const loanId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'loanId'],
  mapper: {
    serializedName: 'loanId',
    type: {
      name: 'Uuid'
    }
  }
};
export const loanId2: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'loanId'],
  mapper: {
    serializedName: 'loanId',
    defaultValue: '',
    type: {
      name: 'String'
    }
  }
};
export const loanType: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'loanType'],
  mapper: {
    serializedName: 'loanType',
    type: {
      name: 'String'
    }
  }
};
export const local: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'local'],
  mapper: {
    serializedName: 'local',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const locationId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'locationId'],
  mapper: {
    serializedName: 'locationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const locTrailing: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'locTrailing'],
  mapper: {
    serializedName: 'locTrailing',
    type: {
      name: 'Number'
    }
  }
};
export const manual: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'manual'],
  mapper: {
    serializedName: 'manual',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const message: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'message'],
  mapper: {
    serializedName: 'message',
    type: {
      name: 'String'
    }
  }
};
export const messageType: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'messageType'],
  mapper: {
    serializedName: 'messageType',
    type: {
      name: 'String'
    }
  }
};
export const missingTransactionId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'missingTransactionId'],
  mapper: {
    serializedName: 'missingTransactionId',
    type: {
      name: 'String'
    }
  }
};
export const mobile: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'mobile'],
  mapper: {
    serializedName: 'mobile',
    type: {
      name: 'String'
    }
  }
};
export const month: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'month'],
  mapper: {
    serializedName: 'month',
    type: {
      name: 'Number'
    }
  }
};
export const name0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'name'],
  mapper: {
    serializedName: 'name',
    type: {
      name: 'String'
    }
  }
};
export const name1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'name'],
  mapper: {
    serializedName: 'name',
    defaultValue: 'Test Application',
    type: {
      name: 'String'
    }
  }
};
export const newAmount: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'newAmount'],
  mapper: {
    serializedName: 'newAmount',
    type: {
      name: 'Number'
    }
  }
};
export const newCustomerId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'newCustomerId'],
  mapper: {
    serializedName: 'newCustomerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const newdate: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'newdate'],
  mapper: {
    serializedName: 'newdate',
    type: {
      name: 'DateTime'
    }
  }
};
export const note: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'note'],
  mapper: {
    serializedName: 'note',
    type: {
      name: 'String'
    }
  }
};
export const noteId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'noteId'],
  mapper: {
    serializedName: 'noteId',
    type: {
      name: 'Uuid'
    }
  }
};
export const noteId1: msRest.OperationURLParameter = {
  parameterPath: 'noteId',
  mapper: {
    required: true,
    serializedName: 'noteId',
    type: {
      name: 'Uuid'
    }
  }
};
export const notes: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'notes'],
  mapper: {
    serializedName: 'notes',
    type: {
      name: 'String'
    }
  }
};
export const notification: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'notification'],
  mapper: {
    serializedName: 'notification',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const notificationId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'notificationId'],
  mapper: {
    serializedName: 'notificationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const notificationId1: msRest.OperationURLParameter = {
  parameterPath: 'notificationId',
  mapper: {
    required: true,
    serializedName: 'notificationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const number: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'number'],
  mapper: {
    serializedName: 'number',
    type: {
      name: 'String'
    }
  }
};
export const offerId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'offerId'],
  mapper: {
    serializedName: 'offerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const offerId1: msRest.OperationURLParameter = {
  parameterPath: 'offerId',
  mapper: {
    required: true,
    serializedName: 'offerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const offerReadyDate: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'offerReadyDate'],
  mapper: {
    serializedName: 'offerReadyDate',
    type: {
      name: 'DateTime'
    }
  }
};
export const oldCustomerId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'oldCustomerId'],
  mapper: {
    serializedName: 'oldCustomerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const organisationId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'organisationId'],
  mapper: {
    serializedName: 'organisationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const organisationId1: msRest.OperationQueryParameter = {
  parameterPath: 'organisationId',
  mapper: {
    required: true,
    serializedName: 'organisationId',
    type: {
      name: 'Uuid'
    }
  }
};
export const partnerCommissionId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'partnerCommissionId'],
  mapper: {
    serializedName: 'partnerCommissionId',
    type: {
      name: 'Uuid'
    }
  }
};
export const partnerId0: msRest.OperationURLParameter = {
  parameterPath: 'partnerId',
  mapper: {
    required: true,
    serializedName: 'partnerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const partnerId1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'partnerId'],
  mapper: {
    serializedName: 'partnerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const partnerId2: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'partnerId'],
  mapper: {
    serializedName: 'PartnerId',
    type: {
      name: 'Uuid'
    }
  }
};
export const paymentAmount: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'paymentAmount'],
  mapper: {
    serializedName: 'paymentAmount',
    type: {
      name: 'Number'
    }
  }
};
export const paymentArrangementId: msRest.OperationQueryParameter = {
  parameterPath: 'paymentArrangementId',
  mapper: {
    required: true,
    serializedName: 'paymentArrangementId',
    type: {
      name: 'Uuid'
    }
  }
};
export const paymentDate: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'paymentDate'],
  mapper: {
    serializedName: 'paymentDate',
    type: {
      name: 'DateTime'
    }
  }
};
export const paymentId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'paymentId'],
  mapper: {
    serializedName: 'paymentId',
    type: {
      name: 'Uuid'
    }
  }
};
export const paymentQueryId0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'paymentQueryId'],
  mapper: {
    serializedName: 'paymentQueryId',
    type: {
      name: 'Uuid'
    }
  }
};
export const paymentQueryId1: msRest.OperationURLParameter = {
  parameterPath: 'paymentQueryId',
  mapper: {
    required: true,
    serializedName: 'paymentQueryId',
    type: {
      name: 'Uuid'
    }
  }
};
export const phone0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'phone'],
  mapper: {
    serializedName: 'phone',
    type: {
      name: 'String'
    }
  }
};
export const phone1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'phone'],
  mapper: {
    serializedName: 'phone',
    type: {
      name: 'Boolean'
    }
  }
};
export const phoneNumber: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'phoneNumber'],
  mapper: {
    serializedName: 'phoneNumber',
    type: {
      name: 'String'
    }
  }
};
export const primaryName: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'primaryName'],
  mapper: {
    serializedName: 'primaryName',
    type: {
      name: 'String'
    }
  }
};
export const principleSplit: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'principleSplit'],
  mapper: {
    serializedName: 'principleSplit',
    type: {
      name: 'Number'
    }
  }
};
export const proposedLimit: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'proposedLimit'],
  mapper: {
    serializedName: 'proposedLimit',
    type: {
      name: 'Number'
    }
  }
};
export const provider: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'provider'],
  mapper: {
    serializedName: 'provider',
    type: {
      name: 'String'
    }
  }
};
export const rate: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'rate'],
  mapper: {
    serializedName: 'rate',
    type: {
      name: 'Number'
    }
  }
};
export const reason: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'reason'],
  mapper: {
    serializedName: 'reason',
    type: {
      name: 'String'
    }
  }
};
export const reasonId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'reasonId'],
  mapper: {
    serializedName: 'reasonId',
    type: {
      name: 'Uuid'
    }
  }
};
export const reconciled: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'reconciled'],
  mapper: {
    serializedName: 'reconciled',
    type: {
      name: 'Boolean'
    }
  }
};
export const repaymentId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'repaymentId'],
  mapper: {
    serializedName: 'repaymentId',
    type: {
      name: 'Uuid'
    }
  }
};
export const request: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'request'],
  mapper: {
    serializedName: 'request',
    type: {
      name: 'String'
    }
  }
};
export const requestId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'requestId'],
  mapper: {
    serializedName: 'requestId',
    type: {
      name: 'Uuid'
    }
  }
};
export const response: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'response'],
  mapper: {
    serializedName: 'response',
    type: {
      name: 'String'
    }
  }
};
export const revolving: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'revolving'],
  mapper: {
    serializedName: 'revolving',
    type: {
      name: 'Boolean'
    }
  }
};
export const searchString: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'searchString'],
  mapper: {
    serializedName: 'searchString',
    type: {
      name: 'String'
    }
  }
};
export const secured: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'secured'],
  mapper: {
    serializedName: 'secured',
    type: {
      name: 'Number'
    }
  }
};
export const securedTrailing: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'securedTrailing'],
  mapper: {
    serializedName: 'securedTrailing',
    type: {
      name: 'Number'
    }
  }
};
export const sendNotification: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'sendNotification'],
  mapper: {
    serializedName: 'sendNotification',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const sendToAgent: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'sendToAgent'],
  mapper: {
    serializedName: 'sendToAgent',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const sendToPartner: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'sendToPartner'],
  mapper: {
    serializedName: 'sendToPartner',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const shouldDelete: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'shouldDelete'],
  mapper: {
    serializedName: 'shouldDelete',
    defaultValue: false,
    type: {
      name: 'Boolean'
    }
  }
};
export const show: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'show'],
  mapper: {
    serializedName: 'show',
    type: {
      name: 'Boolean'
    }
  }
};
export const sms: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'sms'],
  mapper: {
    serializedName: 'sms',
    type: {
      name: 'Boolean'
    }
  }
};
export const source: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'source'],
  mapper: {
    serializedName: 'source',
    defaultValue: 'MoreDataRequired',
    type: {
      name: 'String'
    }
  }
};
export const sourceId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'sourceId'],
  mapper: {
    serializedName: 'sourceId',
    type: {
      name: 'Uuid'
    }
  }
};
export const ssn: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'ssn'],
  mapper: {
    serializedName: 'ssn',
    type: {
      name: 'String'
    }
  }
};
export const startDate: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'startDate'],
  mapper: {
    serializedName: 'startDate',
    type: {
      name: 'DateTime'
    }
  }
};
export const state: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'state'],
  mapper: {
    serializedName: 'state',
    type: {
      name: 'String'
    }
  }
};
export const status0: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'status'],
  mapper: {
    serializedName: 'status',
    type: {
      name: 'String'
    }
  }
};
export const status1: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'status'],
  mapper: {
    serializedName: 'status',
    type: {
      name: 'Boolean'
    }
  }
};
export const status2: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'status'],
  mapper: {
    serializedName: 'status',
    defaultValue: 5,
    type: {
      name: 'Number'
    }
  }
};
export const support: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'support'],
  mapper: {
    serializedName: 'support',
    type: {
      name: 'Boolean'
    }
  }
};
export const supportingDocumentId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'supportingDocumentId'],
  mapper: {
    serializedName: 'supportingDocumentId',
    type: {
      name: 'Uuid'
    }
  }
};
export const syntheticPaymentId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'syntheticPaymentId'],
  mapper: {
    serializedName: 'syntheticPaymentId',
    type: {
      name: 'Uuid'
    }
  }
};
export const sythenticTransactionId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'sythenticTransactionId'],
  mapper: {
    serializedName: 'sythenticTransactionId',
    type: {
      name: 'Uuid'
    }
  }
};
export const term: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'term'],
  mapper: {
    serializedName: 'term',
    defaultValue: 52,
    type: {
      name: 'Number'
    }
  }
};
export const thirdParty: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'thirdParty'],
  mapper: {
    serializedName: 'thirdParty',
    defaultValue: 'moneythumb',
    type: {
      name: 'String'
    }
  }
};
export const title: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'title'],
  mapper: {
    serializedName: 'title',
    type: {
      name: 'String'
    }
  }
};
export const toCredit: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'toCredit'],
  mapper: {
    serializedName: 'toCredit',
    type: {
      name: 'Boolean'
    }
  }
};
export const toIt: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'toIt'],
  mapper: {
    serializedName: 'toIt',
    type: {
      name: 'Boolean'
    }
  }
};
export const toOps: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'toOps'],
  mapper: {
    serializedName: 'toOps',
    type: {
      name: 'Boolean'
    }
  }
};
export const trailing: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'trailing'],
  mapper: {
    serializedName: 'trailing',
    type: {
      name: 'Number'
    }
  }
};
export const trailingFee: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'trailingFee'],
  mapper: {
    serializedName: 'trailingFee',
    type: {
      name: 'Number'
    }
  }
};
export const transactionId: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'transactionId'],
  mapper: {
    serializedName: 'transactionId',
    type: {
      name: 'Uuid'
    }
  }
};
export const type: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'type'],
  mapper: {
    serializedName: 'type',
    defaultValue: 'Line Of Credit Advance',
    type: {
      name: 'String'
    }
  }
};
export const unsecuredTrailing: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'unsecuredTrailing'],
  mapper: {
    serializedName: 'unsecuredTrailing',
    type: {
      name: 'Number'
    }
  }
};
export const updateReason: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'updateReason'],
  mapper: {
    serializedName: 'updateReason',
    type: {
      name: 'Boolean'
    }
  }
};
export const updateReconcilationStatus: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'updateReconcilationStatus'],
  mapper: {
    serializedName: 'updateReconcilationStatus',
    defaultValue: true,
    type: {
      name: 'Boolean'
    }
  }
};
export const upfront: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'upfront'],
  mapper: {
    serializedName: 'upfront',
    type: {
      name: 'Number'
    }
  }
};
export const upfrontFee: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'upfrontFee'],
  mapper: {
    serializedName: 'upfrontFee',
    type: {
      name: 'Number'
    }
  }
};
export const uploadedFile: msRest.OperationParameter = {
  parameterPath: ['options', 'uploadedFile'],
  mapper: {
    serializedName: 'uploadedFile',
    type: {
      name: 'Stream'
    }
  }
};
export const uploadedFiles: msRest.OperationParameter = {
  parameterPath: ['options', 'uploadedFiles'],
  mapper: {
    serializedName: 'uploadedFiles',
    type: {
      name: 'Sequence',
      element: {
        type: {
          name: 'String'
        }
      }
    }
  }
};
export const uri: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'uri'],
  mapper: {
    serializedName: 'uri',
    type: {
      name: 'String'
    }
  }
};
export const url: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'url'],
  mapper: {
    serializedName: 'url',
    type: {
      name: 'String'
    }
  }
};
export const user: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'user'],
  mapper: {
    serializedName: 'user',
    type: {
      name: 'String'
    }
  }
};
export const value: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'value'],
  mapper: {
    serializedName: 'value',
    type: {
      name: 'String'
    }
  }
};
export const weeklyrepayment: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'weeklyrepayment'],
  mapper: {
    serializedName: 'weeklyrepayment',
    type: {
      name: 'Number'
    }
  }
};
export const year: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'year'],
  mapper: {
    serializedName: 'year',
    type: {
      name: 'Number'
    }
  }
};
export const yodlee: msRest.OperationQueryParameter = {
  parameterPath: ['options', 'yodlee'],
  mapper: {
    serializedName: 'yodlee',
    type: {
      name: 'Boolean'
    }
  }
};
