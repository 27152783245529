import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  private loadingSubject = new Subject<boolean>();
  private showButton = new Subject<boolean>();
  private messageSubject = new Subject<string>();
  private progressBarSubject = new Subject<{ value: number; max: number }>();

  constructor(private router: Router) {}

  setStatus(isLoading: boolean) {
    this.loadingSubject.next(isLoading);
  }

  setMessage(message: string) {
    this.messageSubject.next(message);
  }

  turnOnButton() {
    this.showButton.next(true);
  }

  getShowButton(): Observable<boolean> {
    return this.showButton.asObservable();
  }

  getStatus(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  getMessage(): Observable<string> {
    return this.messageSubject.asObservable();
  }

  getProgressBar(): Observable<any> {
    return this.progressBarSubject.asObservable();
  }

  setProgressBarData(value: number, max: number) {
    this.progressBarSubject.next({ value, max });
  }
}
