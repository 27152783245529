import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { ActivatedRoute, NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from 'app/core/services/logger.service';
import { DataStore } from '@app/DataStore';
import { SystemService } from '@app/core/services/system.service';
import { DOCUMENT } from '@angular/common';
import { ApplicationService } from './core/services/application.service';
import { SwUpdate } from '@angular/service-worker';
import { SignalrService } from './core/services/signalr.service';
import { AppNavigatorService } from './core/services/app-navigator.service';
import { ApplicationInsightsService } from './core/services';

const log = new Logger('App');
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  pending = true;
  whitelable = false;
  partnerName = '';
  encorePlexe = 'http://encore.plexe.com';
  plexeHome = window['appConfig']['homeUrl'];
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private signalrService: SignalrService,
    private systemService: SystemService,
    private applicationService: ApplicationService,
    private swUpdate: SwUpdate,
    private navService: AppNavigatorService,
    private appInsightsService: ApplicationInsightsService,
    @Inject(DOCUMENT) private document: Document
  ) {
    let defaultContentWidth = window['appConfig']['defaultContentWidth'];
    let defaultContentHeight = window['appConfig']['defaultContentHeight'];
    let defaultResolutionWidth = window['appConfig']['defaultResolutionWidth'];
    let scale = 1;

    if (window.innerWidth > defaultContentWidth) {
      let scaleX = window.innerWidth / defaultContentWidth;
      let scaleY = window.innerHeight / defaultContentHeight;
      if (scaleX < scaleY) {
        scale = scaleX;
      } else {
        scale = scaleY;
      }
    }

    if (scale < 0.9) {
      scale = 0.9;
    }

    if (scale > 1 && window.innerWidth <= defaultResolutionWidth) {
      scale = 1;
    }

    this.document.documentElement.style.setProperty('--global-scale', scale.toString());

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        try {
          if (typeof ga === 'function') {
            ga('set', 'page', event.urlAfterRedirects);
            ga('send', 'pageview');
            DataStore.isNextPage = true;
          }
        } catch {
          DataStore.isNextPage = true;
        }
      }
    });
  }

  ngOnInit() {
    // Here you can check for available updates
    this.swUpdate.available.subscribe(event => {
      // Activate the new update and reload the page
      this.swUpdate.activateUpdate().then(() => {
        window.location.reload(); // Reload the page to apply the update
      });
    });

    // Listen for service worker update found and state changes
    navigator.serviceWorker.ready.then(registration => {
      registration.onupdatefound = () => {
        const newWorker = registration.installing;

        if (newWorker) {
          newWorker.onstatechange = () => {
            if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
              // New worker installed but not yet controlling
              this.swUpdate.checkForUpdate(); // Triggers SwUpdate available event if a new version is detected
            }
          };
        }
      };
    });

    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    // this.loadApplicationDataWithDetails();
    // this.loadApplicationData();

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));
    const onResolveEnd = this.router.events.pipe(filter(event => event instanceof ResolveEnd));

    onResolveEnd.subscribe((event: ResolveEnd) => {
      if (!event.urlAfterRedirects.startsWith('/loading') && DataStore.loadingPromises.length > 0) {
        DataStore.loadingPromiseCount += 1;
        if (DataStore.loadingPromiseCount > 30) {
          DataStore.loadingPromises.forEach(item => {
            item.catch(err => {
              this.appInsightsService.logException(new Error('Loading promise takes more than 30 attempts. ' + err));
            });
          });

          DataStore.loadingPromiseCount = 0;
          this.systemService.resetLoadingPromises();
          this.navService.navigateError(
            new Error('Loading promise takes more than 30 attempts. ' + event.urlAfterRedirects)
          );
        } else {
          this.router.navigate(['/loading'], {
            queryParams: { fallback: event.urlAfterRedirects, refresh: new Date().getTime() }
          });
        }
      }
      // else {
      //   DataStore.loadingPromiseCount = 0;
      //   this.systemService.resetLoadingPromises();
      // }
    });

    // Change page title on navigation or language change, based on route data
    onNavigationEnd
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        const title = event['title'];
      });

    this.loadSiteTheme();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    let defaultContentWidth = window['appConfig']['defaultContentWidth'];
    let defaultContentHeight = window['appConfig']['defaultContentHeight'];
    let defaultResolutionWidth = window['appConfig']['defaultResolutionWidth'];
    let scale = 1;

    if (window.innerWidth > defaultContentWidth) {
      let scaleX = window.innerWidth / defaultContentWidth;
      let scaleY = window.innerHeight / defaultContentHeight;
      if (scaleX < scaleY) {
        scale = scaleX;
      } else {
        scale = scaleY;
      }
    }

    if (scale < 0.9) {
      scale = 0.9;
    }

    if (scale > 1 && window.innerWidth <= defaultResolutionWidth) {
      scale = 1;
    }

    this.document.documentElement.style.setProperty('--global-scale', scale.toString());
  }

  // private loadApplicationData() {
  //   this.systemService.loadBankList().catch(() => console.info('Failed to load bank list'));
  // }

  // private loadApplicationDataWithDetails() {
  //   this.systemService.loadBankListWithDetails().catch(() => console.info('Failed to load bank list with details'));
  // }

  private loadPlatformSettings() {}

  private loadSiteTheme() {
    let localStorageSupported = typeof window !== 'undefined' && 'localStorage' in window;

    if (localStorageSupported) {
      try {
        localStorage.setItem('whitelabel_enabled', 'false');
        localStorage.removeItem('Whitelabel_Partnerid');
        localStorage.removeItem('Whitelabel_Agentid');
        localStorage.removeItem('Whitelabel_ReturnUrl');
        window['appConfig']['homeUrl'] = this.plexeHome;
        window['appConfig']['contactUsUrl'] = this.plexeHome + '/contactus';
        this.pending = true;
        let hostname = '';
        let info = new URLSearchParams(window.location.search);
        hostname = info.get('whitelabel');
        if (hostname) {
          hostname = info.get('whitelabel');
          // this.partnerName = hostname;
        } else {
          hostname = window.location.hostname;
          this.partnerName = '';
        }
        if (hostname && hostname != 'plexe.portal.co') {
          this.applicationService.loadDesignElementsOnSite(hostname).then(res => {
            this.pending = false;
            if (res && Object.entries(res).length > 0) {
              this.whitelable = true;

              if (localStorage.getItem('credentials')) {
                let userinfo = JSON.parse(localStorage.getItem('credentials'));
                if (userinfo.userType.toLowerCase() == 'partner') {
                  this.partnerName = userinfo.firstName + ' ' + userinfo.lastName;
                }
              }
              localStorage.setItem('whitelabel_enabled', 'true');
              for (const [key, value] of Object.entries(res)) {
                //ReturnUrl
                if (key === 'ReturnUrl') {
                  localStorage.setItem('Whitelabel_ReturnUrl', value.toString());
                  window['appConfig']['homeUrl'] = value;
                  window['appConfig']['contactUsUrl'] = value;
                }
                if (key === 'PartnerId') {
                  localStorage.setItem('Whitelabel_Partnerid', value.toString());
                }
                if (key === 'AgentId') {
                  localStorage.setItem('Whitelabel_Agentid', value.toString());
                }
                if (key === 'Name') {
                  this.partnerName = value.toString();
                }
                if (key === 'plexe-logo-image' || key === 'bg-background-image') {
                  this.document.documentElement.style.setProperty('--' + key, `url(${value})`);
                } else {
                  this.document.documentElement.style.setProperty('--' + key, value.toString());
                }
                //--plexe - more - light - color - 5
                //--plexe - logo - poweredby - image
                if (key === 'DarkThemeEnabled') {
                  var colorValue = value.toString().toLowerCase();
                  if (colorValue == 'true') {
                    this.document.documentElement.style.setProperty(
                      '--plexe-logo-poweredby-image',
                      `url(assets/svgs/broker/plexe_logo_rgb_powered_by_2.svg`
                    );
                  } else {
                    this.document.documentElement.style.setProperty(
                      '--plexe-logo-poweredby-image',
                      'url(assets/svgs/broker/plexe_logo_rgb_powered_by_1.svg)'
                    );
                  }
                }
              }
            }
          });
        } else {
          window['appConfig']['homeUrl'] = this.plexeHome;
          window['appConfig']['contactUsUrl'] = this.plexeHome + '/contactus';
          this.pending = false;
          localStorage.setItem('whitelabel_enabled', 'false');
          localStorage.removeItem('Whitelabel_Partnerid');
          localStorage.removeItem('Whitelabel_Agentid');
          localStorage.removeItem('Whitelabel_ReturnUrl');
        }
      } catch (error) {
        localStorageSupported = false; // Set to false if there's any error (e.g., in incognito mode)
      }
    }

    //this is equal to !!window.localStorage or Boolean(window.localStorage)
    if (!localStorageSupported) {
      alert('Your browser version is not supported. Please upgrade or get a new browser.');
      return;
    }
  }
}
