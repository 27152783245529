import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppNavigatorService } from '@app/core/services/app-navigator.service';
import { LoaderService } from '@app/shared/loader/loader.service';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { UserService } from '@app/core/services/user.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  menuHidden = true;
  homeUrl = window['appConfig']['homeUrl'];
  whitelable = false;
  constructor(
    private loaderService: LoaderService,
    private router: Router,
    private appNavigatorService: AppNavigatorService,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
    this.checkWhiteLable();
  }
  private checkWhiteLable() {
    this.whitelable = JSON.parse(localStorage.getItem('whitelabel_enabled'));
  }
  ngOnInit() {
    this.loaderService.setStatus(true);
    this.loaderService.setMessage('Loading Home Page...');

    let isCredentialsValid = true;
    if (
      !this.authenticationService.credentials ||
      this.authenticationService.credentials == null ||
      !this.authenticationService.credentials.id ||
      this.authenticationService.credentials.id == null
    ) {
      isCredentialsValid = false;
    }

    let p: Promise<any>;
    if (this.authenticationService.isCustomer() && isCredentialsValid) {
      p = this.userService.loadCustomerForHomePage(this.authenticationService.credentials.id).then(_ => {
        return this.appNavigatorService.navigateToCustomerHome();
      });
    } else if (this.authenticationService.isPartner() && isCredentialsValid) {
      p = this.userService.loadPartnerHomepageData(this.authenticationService.credentials.id).then(_ => {
        return this.router.navigate(['/partner/home'], { replaceUrl: true });
      });
    } else if (this.authenticationService.isAgent() && isCredentialsValid) {
      p = this.userService.loadAgentHomepageData(this.authenticationService.credentials.id).then(_ => {
        return this.router.navigate(['/agent/home'], { replaceUrl: true });
      });
    } else {
      if (!this.whitelable) {
        const isSms = sessionStorage.getItem('isSms');
        if (isSms == null) {
          this.userService
            .getStartupConfig()
            .then(res => {
              if (res.body) {
                this.router.navigate(['/login-sms'], { replaceUrl: true });
              } else {
                this.router.navigate(['/login'], { replaceUrl: true });
              }
            })
            .catch(() => this.router.navigate(['/login'], { replaceUrl: true }));
        } else {
          if (JSON.parse(isSms)) {
            this.router.navigate(['/login-sms'], { replaceUrl: true });
          } else {
            this.router.navigate(['/login'], { replaceUrl: true });
          }
        }
      } else {
        this.loaderService.setStatus(false);
      }
    }

    if (p) {
      p.then(_ => {}).catch(err => {
        this.appNavigatorService.navigateError(new Error('Landing page, failed to load. ' + err));
      });
    } else {
      this.appNavigatorService.navigateLogin();
    }
  }
  navigateButton(page) {
    this.router.navigate(['/' + page]);
  }
}
