import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { LoaderService } from '@app/shared/loader/loader.service';
import { from, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AppNavigatorService } from '@app/core/services/app-navigator.service';
import { switchMap } from 'rxjs/internal/operators';
import { UserService } from '@app/core/services/user.service';
import { SecurityContext } from '@app/core/interfaces/i-security-context';
import { IpService } from '../../core/services/ip.service';

@Component({
  selector: 'app-two-factor',
  templateUrl: './two-factor.component.html',
  styleUrls: ['./two-factor.component.scss']
})
export class TwoFactorComponent implements OnInit {
  digit1;
  digit2;
  digit3;
  digit4;
  digit5;
  digit6;
  failed = false;
  ipAddress: string;

  constructor(
    private authenticationService: AuthenticationService,
    private loaderService: LoaderService,
    private router: Router,
    private ipservice: IpService,
    private NavService: AppNavigatorService,
    private userService: UserService
  ) {}

  get digitsFilled() {
    return this.digit1 && this.digit2 && this.digit3 && this.digit4 && this.digit5 && this.digit6;
  }

  get username() {
    return this.authenticationService.credentials.username;
  }

  ngOnInit() {
    this.ipservice.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  onKeyDown(event: any) {
    if (event.which > 47 && event.which < 58) {
      event.currentTarget.value = '';
    }
  }

  onKeyUp(event: any, nextElemId?: string) {
    if (event.which > 47 && event.which < 58 && nextElemId) {
      const e = document.getElementById(nextElemId);
      e.focus();
    }
  }

  proceed() {
    this.failed = false;

    const code = '' + this.digit1 + this.digit2 + this.digit3 + this.digit4 + this.digit5 + this.digit6;

    this.loaderService.setStatus(true);

    this.authenticationService
      .validateTwoWayAuth({ username: this.username, code: code, ipAddress: this.ipAddress })
      .pipe(switchMap(credentials => this.postLoginActions(credentials)))
      .subscribe(
        _ => {},
        _ => {
          this.failed = true;
          this.loaderService.setStatus(false);
        }
      );
  }

  resendSMS() {}

  postLoginActions(credentials: SecurityContext): Observable<any> {
    if (credentials.userType.toLowerCase() === 'partner') {
      return from(
        this.userService
          .loadPartnerHomepageData(credentials.id)
          .then(_ => {
            return this.router.navigate(['/partner/home'], { replaceUrl: true });
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else if (credentials.userType.toLowerCase() === 'customer' || credentials.userType.toLowerCase() === 'admin') {
      return from(
        this.userService
          .loadCustomerForHomePage(credentials.id)
          .then(_ => {
            return this.NavService.navigateToCustomerHome();
          })
          .catch(err => {
            throw new Error('Failed to load User');
          })
      );
    } else {
      throw new Error('User not allowed');
    }
  }
}
