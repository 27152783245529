import { Injectable } from '@angular/core';
import { OriginationReadApi } from '../proxy/Read/originationReadApi';

@Injectable()
export class OrganisationService {
  constructor(private readApi: OriginationReadApi) {}

  ngOnInit(): void {}

  //  ____   ___  ____ _____
  // |  _ \ / _ \/ ___|_   _|
  // | |_) | | | \___ \ | |
  // |  __/| |_| |___) || |
  // |_|    \___/|____/ |_|

  //   ____ _____ _____
  //  / ___| ____|_   _|
  // | |  _|  _|   | |
  // | |_| | |___  | |
  //  \____|_____| |_|

  loadLoanByOrganisationId(organisationId: string) {
    return this.readApi
      .getLoanByOrganisationId(organisationId)
      .then(res => {
        return res;
      })
      .catch(err => {
        return null;
      });
  }

  loadApplicationOnOrganisationId(organisationId: string) {
    return this.readApi
      .getApplicationOnOrganisationId(organisationId)
      .then(res => {
        return res.body;
      })
      .catch(err => {
        return null;
      });
  }

  //  ____  _____ _____
  // / ___|| ____|_   _|
  // \___ \|  _|   | |
  //  ___) | |___  | |
  // |____/|_____| |_|
}
