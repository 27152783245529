/*
 * Code generated by Microsoft (R) AutoRest Code Generator.
 * Changes may cause incorrect behavior and will be lost if the code is
 * regenerated.
 */

import * as msRest from '@azure/ms-rest-js';
import * as Models from './models';
import * as Mappers from './models/mappers';
import * as Parameters from './models/parameters';
import { AuthorizationAPIContext } from './authorizationAPIContext';

class AuthorizationAPI extends AuthorizationAPIContext {
  /**
   * Initializes a new instance of the AuthorizationAPI class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param [options] The parameter options
   */
  constructor(credentials: msRest.ServiceClientCredentials, options?: Models.AuthorizationAPIOptions) {
    super(credentials, options);
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.CreateTokenResponse>
   */
  createToken(options?: Models.AuthorizationAPICreateTokenOptionalParams): Promise<Models.CreateTokenResponse>;
  /**
   * @param callback The callback
   */
  createToken(callback: msRest.ServiceCallback<Models.FullApplicationUser>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  createToken(
    options: Models.AuthorizationAPICreateTokenOptionalParams,
    callback: msRest.ServiceCallback<Models.FullApplicationUser>
  ): void;
  createToken(
    options?: Models.AuthorizationAPICreateTokenOptionalParams | msRest.ServiceCallback<Models.FullApplicationUser>,
    callback?: msRest.ServiceCallback<Models.FullApplicationUser>
  ): Promise<Models.CreateTokenResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      createTokenOperationSpec,
      callback
    ) as Promise<Models.CreateTokenResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ValidateTwoFactorLoginResponse>
   */
  validateTwoFactorLogin(
    options?: Models.AuthorizationAPIValidateTwoFactorLoginOptionalParams
  ): Promise<Models.ValidateTwoFactorLoginResponse>;
  /**
   * @param callback The callback
   */
  validateTwoFactorLogin(callback: msRest.ServiceCallback<Models.FullApplicationUser>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  validateTwoFactorLogin(
    options: Models.AuthorizationAPIValidateTwoFactorLoginOptionalParams,
    callback: msRest.ServiceCallback<Models.FullApplicationUser>
  ): void;
  validateTwoFactorLogin(
    options?:
      | Models.AuthorizationAPIValidateTwoFactorLoginOptionalParams
      | msRest.ServiceCallback<Models.FullApplicationUser>,
    callback?: msRest.ServiceCallback<Models.FullApplicationUser>
  ): Promise<Models.ValidateTwoFactorLoginResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      validateTwoFactorLoginOperationSpec,
      callback
    ) as Promise<Models.ValidateTwoFactorLoginResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.ResendTwoFactorOtpResponse>
   */
  resendTwoFactorOtp(
    options?: Models.AuthorizationAPIResendTwoFactorOtpOptionalParams
  ): Promise<Models.ResendTwoFactorOtpResponse>;
  /**
   * @param callback The callback
   */
  resendTwoFactorOtp(callback: msRest.ServiceCallback<Models.FullApplicationUser>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  resendTwoFactorOtp(
    options: Models.AuthorizationAPIResendTwoFactorOtpOptionalParams,
    callback: msRest.ServiceCallback<Models.FullApplicationUser>
  ): void;
  resendTwoFactorOtp(
    options?:
      | Models.AuthorizationAPIResendTwoFactorOtpOptionalParams
      | msRest.ServiceCallback<Models.FullApplicationUser>,
    callback?: msRest.ServiceCallback<Models.FullApplicationUser>
  ): Promise<Models.ResendTwoFactorOtpResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      resendTwoFactorOtpOperationSpec,
      callback
    ) as Promise<Models.ResendTwoFactorOtpResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetUserResponse>
   */
  getUser(options?: Models.AuthorizationAPIGetUserOptionalParams): Promise<Models.GetUserResponse>;
  /**
   * @param callback The callback
   */
  getUser(callback: msRest.ServiceCallback<Models.UserModel>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getUser(
    options: Models.AuthorizationAPIGetUserOptionalParams,
    callback: msRest.ServiceCallback<Models.UserModel>
  ): void;
  getUser(
    options?: Models.AuthorizationAPIGetUserOptionalParams | msRest.ServiceCallback<Models.UserModel>,
    callback?: msRest.ServiceCallback<Models.UserModel>
  ): Promise<Models.GetUserResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getUserOperationSpec,
      callback
    ) as Promise<Models.GetUserResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DoesUserExistResponse>
   */
  doesUserExist(options?: Models.AuthorizationAPIDoesUserExistOptionalParams): Promise<Models.DoesUserExistResponse>;
  /**
   * @param callback The callback
   */
  doesUserExist(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  doesUserExist(
    options: Models.AuthorizationAPIDoesUserExistOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  doesUserExist(
    options?: Models.AuthorizationAPIDoesUserExistOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.DoesUserExistResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      doesUserExistOperationSpec,
      callback
    ) as Promise<Models.DoesUserExistResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetUserByIdResponse>
   */
  getUserById(options?: Models.AuthorizationAPIGetUserByIdOptionalParams): Promise<Models.GetUserByIdResponse>;
  /**
   * @param callback The callback
   */
  getUserById(callback: msRest.ServiceCallback<Models.UserModel>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getUserById(
    options: Models.AuthorizationAPIGetUserByIdOptionalParams,
    callback: msRest.ServiceCallback<Models.UserModel>
  ): void;
  getUserById(
    options?: Models.AuthorizationAPIGetUserByIdOptionalParams | msRest.ServiceCallback<Models.UserModel>,
    callback?: msRest.ServiceCallback<Models.UserModel>
  ): Promise<Models.GetUserByIdResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getUserByIdOperationSpec,
      callback
    ) as Promise<Models.GetUserByIdResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.DeleteUserResponse>
   */
  deleteUser(options?: Models.AuthorizationAPIDeleteUserOptionalParams): Promise<Models.DeleteUserResponse>;
  /**
   * @param callback The callback
   */
  deleteUser(callback: msRest.ServiceCallback<Models.UserModel>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  deleteUser(
    options: Models.AuthorizationAPIDeleteUserOptionalParams,
    callback: msRest.ServiceCallback<Models.UserModel>
  ): void;
  deleteUser(
    options?: Models.AuthorizationAPIDeleteUserOptionalParams | msRest.ServiceCallback<Models.UserModel>,
    callback?: msRest.ServiceCallback<Models.UserModel>
  ): Promise<Models.DeleteUserResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      deleteUserOperationSpec,
      callback
    ) as Promise<Models.DeleteUserResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetHistoricalLoginsResponse>
   */
  getHistoricalLogins(
    options?: Models.AuthorizationAPIGetHistoricalLoginsOptionalParams
  ): Promise<Models.GetHistoricalLoginsResponse>;
  /**
   * @param callback The callback
   */
  getHistoricalLogins(callback: msRest.ServiceCallback<Models.HistoricalLoginModelPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getHistoricalLogins(
    options: Models.AuthorizationAPIGetHistoricalLoginsOptionalParams,
    callback: msRest.ServiceCallback<Models.HistoricalLoginModelPagedList>
  ): void;
  getHistoricalLogins(
    options?:
      | Models.AuthorizationAPIGetHistoricalLoginsOptionalParams
      | msRest.ServiceCallback<Models.HistoricalLoginModelPagedList>,
    callback?: msRest.ServiceCallback<Models.HistoricalLoginModelPagedList>
  ): Promise<Models.GetHistoricalLoginsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getHistoricalLoginsOperationSpec,
      callback
    ) as Promise<Models.GetHistoricalLoginsResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetAllInternalUsersResponse>
   */
  getAllInternalUsers(
    options?: Models.AuthorizationAPIGetAllInternalUsersOptionalParams
  ): Promise<Models.GetAllInternalUsersResponse>;
  /**
   * @param callback The callback
   */
  getAllInternalUsers(callback: msRest.ServiceCallback<Models.UserModelPagedList>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getAllInternalUsers(
    options: Models.AuthorizationAPIGetAllInternalUsersOptionalParams,
    callback: msRest.ServiceCallback<Models.UserModelPagedList>
  ): void;
  getAllInternalUsers(
    options?:
      | Models.AuthorizationAPIGetAllInternalUsersOptionalParams
      | msRest.ServiceCallback<Models.UserModelPagedList>,
    callback?: msRest.ServiceCallback<Models.UserModelPagedList>
  ): Promise<Models.GetAllInternalUsersResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getAllInternalUsersOperationSpec,
      callback
    ) as Promise<Models.GetAllInternalUsersResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.RefreshTokenResponse>
   */
  refreshToken(options?: msRest.RequestOptionsBase): Promise<Models.RefreshTokenResponse>;
  /**
   * @param callback The callback
   */
  refreshToken(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  refreshToken(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string>): void;
  refreshToken(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.RefreshTokenResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      refreshTokenOperationSpec,
      callback
    ) as Promise<Models.RefreshTokenResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  register(options?: Models.AuthorizationAPIRegisterOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  register(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  register(options: Models.AuthorizationAPIRegisterOptionalParams, callback: msRest.ServiceCallback<void>): void;
  register(
    options?: Models.AuthorizationAPIRegisterOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      registerOperationSpec,
      callback
    );
  }

  /**
   * @param userName
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  update(userName: string, options?: Models.AuthorizationAPIUpdateOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param userName
   * @param callback The callback
   */
  update(userName: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userName
   * @param options The optional parameters
   * @param callback The callback
   */
  update(
    userName: string,
    options: Models.AuthorizationAPIUpdateOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  update(
    userName: string,
    options?: Models.AuthorizationAPIUpdateOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userName,
        options
      },
      updateOperationSpec,
      callback
    );
  }

  /**
   * @param userId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateById(userId: string, options?: Models.AuthorizationAPIUpdateByIdOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param userId
   * @param callback The callback
   */
  updateById(userId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userId
   * @param options The optional parameters
   * @param callback The callback
   */
  updateById(
    userId: string,
    options: Models.AuthorizationAPIUpdateByIdOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateById(
    userId: string,
    options?: Models.AuthorizationAPIUpdateByIdOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userId,
        options
      },
      updateByIdOperationSpec,
      callback
    );
  }

  /**
   * @param userName
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  reset(userName: string, options?: Models.AuthorizationAPIResetOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param userName
   * @param callback The callback
   */
  reset(userName: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userName
   * @param options The optional parameters
   * @param callback The callback
   */
  reset(
    userName: string,
    options: Models.AuthorizationAPIResetOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  reset(
    userName: string,
    options?: Models.AuthorizationAPIResetOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userName,
        options
      },
      resetOperationSpec,
      callback
    );
  }

  /**
   * @param userName
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  passwordExpiresReset(
    userName: string,
    options?: Models.AuthorizationAPIPasswordExpiresResetOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param userName
   * @param callback The callback
   */
  passwordExpiresReset(userName: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userName
   * @param options The optional parameters
   * @param callback The callback
   */
  passwordExpiresReset(
    userName: string,
    options: Models.AuthorizationAPIPasswordExpiresResetOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  passwordExpiresReset(
    userName: string,
    options?: Models.AuthorizationAPIPasswordExpiresResetOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userName,
        options
      },
      passwordExpiresResetOperationSpec,
      callback
    );
  }

  /**
   * @param userName
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disable(userName: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param userName
   * @param callback The callback
   */
  disable(userName: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userName
   * @param options The optional parameters
   * @param callback The callback
   */
  disable(userName: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  disable(
    userName: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userName,
        options
      },
      disableOperationSpec,
      callback
    );
  }

  /**
   * @param userName
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enable(userName: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param userName
   * @param callback The callback
   */
  enable(userName: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userName
   * @param options The optional parameters
   * @param callback The callback
   */
  enable(userName: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  enable(
    userName: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userName,
        options
      },
      enableOperationSpec,
      callback
    );
  }

  /**
   * @param userName
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  enableTwoFactorAuthentication(userName: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param userName
   * @param callback The callback
   */
  enableTwoFactorAuthentication(userName: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userName
   * @param options The optional parameters
   * @param callback The callback
   */
  enableTwoFactorAuthentication(
    userName: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  enableTwoFactorAuthentication(
    userName: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userName,
        options
      },
      enableTwoFactorAuthenticationOperationSpec,
      callback
    );
  }

  /**
   * @param userName
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disableTwoFactorAuthentication(userName: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param userName
   * @param callback The callback
   */
  disableTwoFactorAuthentication(userName: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userName
   * @param options The optional parameters
   * @param callback The callback
   */
  disableTwoFactorAuthentication(
    userName: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  disableTwoFactorAuthentication(
    userName: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userName,
        options
      },
      disableTwoFactorAuthenticationOperationSpec,
      callback
    );
  }

  /**
   * @param userName
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  signOut(userName: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param userName
   * @param callback The callback
   */
  signOut(userName: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userName
   * @param options The optional parameters
   * @param callback The callback
   */
  signOut(userName: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  signOut(
    userName: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userName,
        options
      },
      signOutOperationSpec,
      callback
    );
  }

  /**
   * @param userEmail
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  passwordReset(
    userEmail: string,
    options?: Models.AuthorizationAPIPasswordResetOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param userEmail
   * @param callback The callback
   */
  passwordReset(userEmail: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userEmail
   * @param options The optional parameters
   * @param callback The callback
   */
  passwordReset(
    userEmail: string,
    options: Models.AuthorizationAPIPasswordResetOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  passwordReset(
    userEmail: string,
    options?: Models.AuthorizationAPIPasswordResetOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userEmail,
        options
      },
      passwordResetOperationSpec,
      callback
    );
  }

  /**
   * @param passwordResetToken
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  passwordResetConfirmation(
    passwordResetToken: string,
    options?: Models.AuthorizationAPIPasswordResetConfirmationOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param passwordResetToken
   * @param callback The callback
   */
  passwordResetConfirmation(passwordResetToken: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param passwordResetToken
   * @param options The optional parameters
   * @param callback The callback
   */
  passwordResetConfirmation(
    passwordResetToken: string,
    options: Models.AuthorizationAPIPasswordResetConfirmationOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  passwordResetConfirmation(
    passwordResetToken: string,
    options?: Models.AuthorizationAPIPasswordResetConfirmationOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        passwordResetToken,
        options
      },
      passwordResetConfirmationOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.IsLoginSmsResponse>
   */
  isLoginSms(options?: msRest.RequestOptionsBase): Promise<Models.IsLoginSmsResponse>;
  /**
   * @param callback The callback
   */
  isLoginSms(callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  isLoginSms(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<boolean>): void;
  isLoginSms(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.IsLoginSmsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      isLoginSmsOperationSpec,
      callback
    ) as Promise<Models.IsLoginSmsResponse>;
  }

  /**
   * @param userId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  setUserRoles(
    userId: string,
    options?: Models.AuthorizationAPISetUserRolesOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param userId
   * @param callback The callback
   */
  setUserRoles(userId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userId
   * @param options The optional parameters
   * @param callback The callback
   */
  setUserRoles(
    userId: string,
    options: Models.AuthorizationAPISetUserRolesOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  setUserRoles(
    userId: string,
    options?: Models.AuthorizationAPISetUserRolesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userId,
        options
      },
      setUserRolesOperationSpec,
      callback
    );
  }

  /**
   * @param userId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeUserRoles(
    userId: string,
    options?: Models.AuthorizationAPIRemoveUserRolesOptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param userId
   * @param callback The callback
   */
  removeUserRoles(userId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeUserRoles(
    userId: string,
    options: Models.AuthorizationAPIRemoveUserRolesOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  removeUserRoles(
    userId: string,
    options?: Models.AuthorizationAPIRemoveUserRolesOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userId,
        options
      },
      removeUserRolesOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetRoleLevelsResponse>
   */
  getRoleLevels(options?: msRest.RequestOptionsBase): Promise<Models.GetRoleLevelsResponse>;
  /**
   * @param callback The callback
   */
  getRoleLevels(callback: msRest.ServiceCallback<string[]>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getRoleLevels(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<string[]>): void;
  getRoleLevels(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<string[]>,
    callback?: msRest.ServiceCallback<string[]>
  ): Promise<Models.GetRoleLevelsResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getRoleLevelsOperationSpec,
      callback
    ) as Promise<Models.GetRoleLevelsResponse>;
  }

  /**
   * @param userId
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  removeEmployee(userId: string, options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param userId
   * @param callback The callback
   */
  removeEmployee(userId: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param userId
   * @param options The optional parameters
   * @param callback The callback
   */
  removeEmployee(userId: string, options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  removeEmployee(
    userId: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        userId,
        options
      },
      removeEmployeeOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  disableInactiveUsers(options?: msRest.RequestOptionsBase): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  disableInactiveUsers(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  disableInactiveUsers(options: msRest.RequestOptionsBase, callback: msRest.ServiceCallback<void>): void;
  disableInactiveUsers(
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      disableInactiveUsersOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  addFirstTimeAccount(options?: Models.AuthorizationAPIAddFirstTimeAccountOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  addFirstTimeAccount(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  addFirstTimeAccount(
    options: Models.AuthorizationAPIAddFirstTimeAccountOptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  addFirstTimeAccount(
    options?: Models.AuthorizationAPIAddFirstTimeAccountOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      addFirstTimeAccountOperationSpec,
      callback
    );
  }

  /**
   * @param email
   * @param phone
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  updateUserPhoneNumber(
    email: string,
    phone: string,
    options?: msRest.RequestOptionsBase
  ): Promise<msRest.RestResponse>;
  /**
   * @param email
   * @param phone
   * @param callback The callback
   */
  updateUserPhoneNumber(email: string, phone: string, callback: msRest.ServiceCallback<void>): void;
  /**
   * @param email
   * @param phone
   * @param options The optional parameters
   * @param callback The callback
   */
  updateUserPhoneNumber(
    email: string,
    phone: string,
    options: msRest.RequestOptionsBase,
    callback: msRest.ServiceCallback<void>
  ): void;
  updateUserPhoneNumber(
    email: string,
    phone: string,
    options?: msRest.RequestOptionsBase | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        email,
        phone,
        options
      },
      updateUserPhoneNumberOperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  index(options?: Models.AuthorizationAPIIndexOptionalParams): Promise<msRest.RestResponse>;
  /**
   * @param callback The callback
   */
  index(callback: msRest.ServiceCallback<void>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  index(options: Models.AuthorizationAPIIndexOptionalParams, callback: msRest.ServiceCallback<void>): void;
  index(
    options?: Models.AuthorizationAPIIndexOptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      indexOperationSpec,
      callback
    );
  }

  /**
   * @param password
   * @param passwordConfirmation
   * @param [options] The optional parameters
   * @returns Promise<msRest.RestResponse>
   */
  passwordResetConfirmation1(
    password: string,
    passwordConfirmation: string,
    options?: Models.AuthorizationAPIPasswordResetConfirmation1OptionalParams
  ): Promise<msRest.RestResponse>;
  /**
   * @param password
   * @param passwordConfirmation
   * @param callback The callback
   */
  passwordResetConfirmation1(
    password: string,
    passwordConfirmation: string,
    callback: msRest.ServiceCallback<void>
  ): void;
  /**
   * @param password
   * @param passwordConfirmation
   * @param options The optional parameters
   * @param callback The callback
   */
  passwordResetConfirmation1(
    password: string,
    passwordConfirmation: string,
    options: Models.AuthorizationAPIPasswordResetConfirmation1OptionalParams,
    callback: msRest.ServiceCallback<void>
  ): void;
  passwordResetConfirmation1(
    password: string,
    passwordConfirmation: string,
    options?: Models.AuthorizationAPIPasswordResetConfirmation1OptionalParams | msRest.ServiceCallback<void>,
    callback?: msRest.ServiceCallback<void>
  ): Promise<msRest.RestResponse> {
    return this.sendOperationRequest(
      {
        password,
        passwordConfirmation,
        options
      },
      passwordResetConfirmation1OperationSpec,
      callback
    );
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetResponse>
   */
  get(options?: Models.AuthorizationAPIGetOptionalParams): Promise<Models.GetResponse>;
  /**
   * @param callback The callback
   */
  get(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  get(options: Models.AuthorizationAPIGetOptionalParams, callback: msRest.ServiceCallback<string>): void;
  get(
    options?: Models.AuthorizationAPIGetOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getOperationSpec,
      callback
    ) as Promise<Models.GetResponse>;
  }

  /**
   * @param [options] The optional parameters
   * @returns Promise<Models.GetVariableOtpLengthResponse>
   */
  getVariableOtpLength(
    options?: Models.AuthorizationAPIGetVariableOtpLengthOptionalParams
  ): Promise<Models.GetVariableOtpLengthResponse>;
  /**
   * @param callback The callback
   */
  getVariableOtpLength(callback: msRest.ServiceCallback<string>): void;
  /**
   * @param options The optional parameters
   * @param callback The callback
   */
  getVariableOtpLength(
    options: Models.AuthorizationAPIGetVariableOtpLengthOptionalParams,
    callback: msRest.ServiceCallback<string>
  ): void;
  getVariableOtpLength(
    options?: Models.AuthorizationAPIGetVariableOtpLengthOptionalParams | msRest.ServiceCallback<string>,
    callback?: msRest.ServiceCallback<string>
  ): Promise<Models.GetVariableOtpLengthResponse> {
    return this.sendOperationRequest(
      {
        options
      },
      getVariableOtpLengthOperationSpec,
      callback
    ) as Promise<Models.GetVariableOtpLengthResponse>;
  }

  /**
   * @param otp
   * @param [options] The optional parameters
   * @returns Promise<Models.ValidateOtpResponse>
   */
  validateOtp(
    otp: string,
    options?: Models.AuthorizationAPIValidateOtpOptionalParams
  ): Promise<Models.ValidateOtpResponse>;
  /**
   * @param otp
   * @param callback The callback
   */
  validateOtp(otp: string, callback: msRest.ServiceCallback<boolean>): void;
  /**
   * @param otp
   * @param options The optional parameters
   * @param callback The callback
   */
  validateOtp(
    otp: string,
    options: Models.AuthorizationAPIValidateOtpOptionalParams,
    callback: msRest.ServiceCallback<boolean>
  ): void;
  validateOtp(
    otp: string,
    options?: Models.AuthorizationAPIValidateOtpOptionalParams | msRest.ServiceCallback<boolean>,
    callback?: msRest.ServiceCallback<boolean>
  ): Promise<Models.ValidateOtpResponse> {
    return this.sendOperationRequest(
      {
        otp,
        options
      },
      validateOtpOperationSpec,
      callback
    ) as Promise<Models.ValidateOtpResponse>;
  }
}

// Operation Specifications
const serializer = new msRest.Serializer(Mappers);
const createTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/token',
  queryParameters: [Parameters.force],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.LoginModel
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.FullApplicationUser
    },
    default: {}
  },
  serializer
};

const validateTwoFactorLoginOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/validate-two-factor',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.TwoFactorModel
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {
      bodyMapper: Mappers.FullApplicationUser
    },
    default: {}
  },
  serializer
};

const resendTwoFactorOtpOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/resend-two-factor',
  queryParameters: [Parameters.userName0],
  responses: {
    200: {
      bodyMapper: Mappers.FullApplicationUser
    },
    default: {}
  },
  serializer
};

const getUserOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/get-user',
  queryParameters: [Parameters.username],
  responses: {
    200: {
      bodyMapper: Mappers.UserModel
    },
    default: {}
  },
  serializer
};

const doesUserExistOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/does-user-exist',
  queryParameters: [Parameters.username, Parameters.code],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    default: {}
  },
  serializer
};

const getUserByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Account/get-user-by-id',
  queryParameters: [Parameters.id],
  responses: {
    200: {
      bodyMapper: Mappers.UserModel
    },
    default: {}
  },
  serializer
};

const deleteUserOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/delete-user',
  queryParameters: [Parameters.id],
  responses: {
    200: {
      bodyMapper: Mappers.UserModel
    },
    default: {}
  },
  serializer
};

const getHistoricalLoginsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Account/get-historical-logins',
  queryParameters: [
    Parameters.fromDateTimeUtc,
    Parameters.toDateTimeUtc,
    Parameters.skip,
    Parameters.take0,
    Parameters.orderBy
  ],
  responses: {
    200: {
      bodyMapper: Mappers.HistoricalLoginModelPagedList
    },
    default: {}
  },
  serializer
};

const getAllInternalUsersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Account/get-all-internal-users',
  queryParameters: [Parameters.searchText, Parameters.skip, Parameters.take1, Parameters.direction, Parameters.column],
  responses: {
    200: {
      bodyMapper: Mappers.UserModelPagedList
    },
    default: {}
  },
  serializer
};

const refreshTokenOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/refreshtoken',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    default: {}
  },
  serializer
};

const registerOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/register',
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.RegisterModel
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userName}/admin-update',
  urlParameters: [Parameters.userName1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.UpdateModel
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateByIdOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userId}/update',
  urlParameters: [Parameters.userId],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.UpdateModel
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const resetOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userName}/admin-password-reset',
  urlParameters: [Parameters.userName1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.PasswordResetModel
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const passwordExpiresResetOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userName}/password-expires-reset',
  urlParameters: [Parameters.userName1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.PasswordResetModel
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const disableOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userName}/admin-disable-account',
  urlParameters: [Parameters.userName1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const enableOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userName}/admin-enable-account',
  urlParameters: [Parameters.userName1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const enableTwoFactorAuthenticationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userName}/enable-two-factor-authentication',
  urlParameters: [Parameters.userName1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const disableTwoFactorAuthenticationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userName}/disable-two-factor-authentication',
  urlParameters: [Parameters.userName1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const signOutOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userName}/sign-out',
  urlParameters: [Parameters.userName1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const passwordResetOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userEmail}/password-reset',
  urlParameters: [Parameters.userEmail0],
  queryParameters: [Parameters.baseUrl],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const passwordResetConfirmationOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/password-reset-confirmation/{passwordResetToken}',
  urlParameters: [Parameters.passwordResetToken0],
  queryParameters: [Parameters.userEmail1],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: Mappers.PasswordResetModel
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const isLoginSmsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Account/is-login-sms',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    default: {}
  },
  serializer
};

const setUserRolesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userId}/set-user-roles',
  urlParameters: [Parameters.userId],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const removeUserRolesOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userId}/remove-user-roles',
  urlParameters: [Parameters.userId],
  requestBody: {
    parameterPath: ['options', 'body'],
    mapper: {
      serializedName: 'body',
      type: {
        name: 'Sequence',
        element: {
          type: {
            name: 'String'
          }
        }
      }
    }
  },
  contentType: 'application/json-patch+json; charset=utf-8',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getRoleLevelsOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Account/get-role-levels',
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Sequence',
          element: {
            type: {
              name: 'String'
            }
          }
        }
      }
    },
    default: {}
  },
  serializer
};

const removeEmployeeOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/{userId}/remove-employee',
  urlParameters: [Parameters.userId],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const disableInactiveUsersOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/disable-inactive-users',
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const addFirstTimeAccountOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/add-first-time-account',
  queryParameters: [Parameters.id],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const updateUserPhoneNumberOperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'api/Account/update-user-phonenumber/{email}/{phone}',
  urlParameters: [Parameters.email0, Parameters.phone],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const indexOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'password-reset-confirmation',
  queryParameters: [Parameters.passwordResetToken1, Parameters.email1],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const passwordResetConfirmation1OperationSpec: msRest.OperationSpec = {
  httpMethod: 'POST',
  path: 'password-reset-confirmation-submit',
  queryParameters: [
    Parameters.passwordResetToken1,
    Parameters.email1,
    Parameters.currentPassword,
    Parameters.password,
    Parameters.passwordConfirmation
  ],
  responses: {
    200: {},
    default: {}
  },
  serializer
};

const getOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Otp/get',
  queryParameters: [Parameters.username, Parameters.length],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    default: {}
  },
  serializer
};

const getVariableOtpLengthOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Otp/get-variable-length',
  queryParameters: [Parameters.length],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'String'
        }
      }
    },
    default: {}
  },
  serializer
};

const validateOtpOperationSpec: msRest.OperationSpec = {
  httpMethod: 'GET',
  path: 'api/Otp/validate/{otp}',
  urlParameters: [Parameters.otp],
  queryParameters: [Parameters.username],
  responses: {
    200: {
      bodyMapper: {
        serializedName: 'parsedResponse',
        type: {
          name: 'Boolean'
        }
      }
    },
    default: {}
  },
  serializer
};

export {
  AuthorizationAPI,
  AuthorizationAPIContext,
  Models as AuthorizationAPIModels,
  Mappers as AuthorizationAPIMappers
};
